import { useContext } from 'react'

import { PageContext } from '../context'

import RowName from './RowName'
import { SystemContext } from '../../../../shared/context'
import { correctMinMax } from '../../../../helper/ShowData'
import SimulateTable from '../../../../shared/SimulateTable/SimulateTable'
import EmptyTable from '../../../../shared/EmptyTable'
import PropTypes from 'prop-types'
import DataTableTemperature from '../DataTableTemperature'
import useAbortFetch from '../../../../hooks/useAbortFetch'
import { useIsPrintMode } from '../../../../hooks/useIsPrintMode'
import hourCell from '../CustomCell'

const columns = systemJson => {
	const allColumns = []

	// First Column
	allColumns.push({
		name: '',
		cell: row => <RowName device={row.device} />,
		grow: 5,
		minWidth: '230px',
		maxWidth: '350px',
	})

	// Min/Max
	allColumns.push({
		name: <div className="text-xss">Soll</div>,
		minWidth: '40px',
		grow: 1,
		compact: 'true',
		center: 'true',
		selector: row => {
			const minMaxEntry = correctMinMax(
				systemJson.temperatureThresholds.find(
					({ place }) => place === row.device.installation_place
				)
			)

			return (
				<div className="italic text-center text-xss">
					{minMaxEntry.target.first} <br /> {minMaxEntry.target.second}
				</div>
			)
		},
	})

	// Hourly Columns
	for (let hour = 0; hour <= 23; hour++) {
		allColumns.push(hourCell(hour))
	}

	return allColumns
}

// TODO: RowName automatisch die richtige Kalenderwoche nutzen, in der man draufdrückt - (auf "Wochenübersicht" innerhalb des Gerätes in day-Übersicht)
const DayTable = ({ currentDay }) => {
	const { systemJson } = useContext(SystemContext)
	const { getTemperatureData, temperatureData, allDevices } =
		useContext(PageContext)

	const initData = async abortController => {
		const timeRange = { startDate: currentDay, days: 1 }
		await getTemperatureData(abortController, timeRange, allDevices)
	}

	const isPrintMode = useIsPrintMode()

	useAbortFetch(initData, [allDevices, currentDay])

	return (
		<>
			{temperatureData?.length > 0 && (
				<>
					{isPrintMode ? (
						<DataTableTemperature
							columns={columns(systemJson)}
							data={temperatureData}
							print
						/>
					) : (
						<DataTableTemperature
							columns={columns(systemJson)}
							data={temperatureData}
						/>
					)}
				</>
			)}
			{temperatureData === null && (
				<SimulateTable
					columns={columns(systemJson)}
					rows={3}
					dummyEntry={{ values: {}, device: {} }}
				/>
			)}
			{temperatureData?.length === 0 && (
				<EmptyTable>
					Es konnten leider keine Geräte in dieser Filiale gefunden werden!
				</EmptyTable>
			)}
		</>
	)
}

DayTable.propTypes = {
	currentDay: PropTypes.instanceOf(Date).isRequired,
}

export default DayTable
