import CodeHandler from '../CodeHandler/CodeHandler'

export const fetchPicture = async (Request, token, id) => {
	const getPictureRequest = await Request(`/Device/${id}/attachment`, token)

	if (!getPictureRequest.ok) {
		CodeHandler(216100)
		return null
	}

	const selectedPhoto = getPictureRequest.data

	if (
		selectedPhoto &&
		(selectedPhoto.length === 0 || selectedPhoto[0].data === '')
	) {
		CodeHandler(216101)
		return null
	}

	return selectedPhoto[0]
}
