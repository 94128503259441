import { useEffect, useRef, useContext, useCallback } from 'react'
import PropTypes from 'prop-types'
import { InsideDropDown } from './InsideDropDown'
import { SystemContext } from '../../context'

const getPosition = position => {
	if (position === 'left') return 'left-0'
	if (position === 'right') return 'right-0'
	if (position === 'center') return 'justify-center'
}

const useDropdown = (dropdownId, showDropdown, setShowDropdown) => {
	const { dropdownState } = useContext(SystemContext)
	const [activeDropdown, setActiveDropdown] = dropdownState

	const triggerRef = useRef(null)

	const handleOpen = useCallback(() => {
		setShowDropdown(true)
		setActiveDropdown(dropdownId)
	}, [dropdownId, setShowDropdown])

	useEffect(() => {
		setActiveDropdown(showDropdown ? dropdownId : null)
	}, [showDropdown, dropdownId])

	return {
		activeDropdown,
		triggerRef,
		handleOpen,
	}
}

const Dropdown = props => {
	const {
		renderTrigger,
		children,
		position = 'left',
		showDropdown,
		setShowDropdown,
		className = '',
		dropdownId = Math.random().toString(),
	} = props

	const { dropdownState } = useContext(SystemContext)
	const [activeDropdown, setActiveDropdown] = dropdownState

	const Element = renderTrigger
	const myPosition = getPosition(position)

	const { triggerRef, handleOpen } = useDropdown(
		dropdownId,
		showDropdown,
		setShowDropdown
	)

	return (
		<div className="relative w-full h-full">
			<button
				ref={triggerRef}
				className={'isButtonActionDropdown flex h-full w-full ' + myPosition}
				onClick={handleOpen}
			>
				<Element />
			</button>

			{showDropdown && activeDropdown === dropdownId && (
				<InsideDropDown
					className={className}
					myPosition={myPosition}
					triggerRef={triggerRef}
					dropdownId={dropdownId}
					setActiveDropdown={setActiveDropdown}
				>
					{children}
				</InsideDropDown>
			)}
		</div>
	)
}

Dropdown.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
	dropdownId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	position: PropTypes.oneOf(['left', 'right', 'center']),
	renderTrigger: PropTypes.elementType.isRequired,
	setShowDropdown: PropTypes.func.isRequired,
	showDropdown: PropTypes.bool.isRequired,
}

export default Dropdown
