/* eslint-disable tailwindcss/no-custom-classname */
import PropTypes from 'prop-types'

const TodayButton = ({ today, handleDateSelect }) => {
	const clickToday = () => handleDateSelect(new Date())

	if (today) {
		return (
			<button
				className="rdp flex !justify-center w-full text-base font-bold rdp-button rdp-button_reset"
				onClick={clickToday}
			>
				{today}
			</button>
		)
	}
}

TodayButton.propTypes = {
	today: PropTypes.string,
	handleDateSelect: PropTypes.func.isRequired,
}

export default TodayButton
