import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'

const ActiveSensors = ({ text, number, link, isRed }) => {
	const { to, name } = link
	return (
		<div className="flex h-full w-full flex-col pt-2">
			<div className="w-full text-center text-xl font-bold">{text}</div>
			<div
				className={
					(isRed ? 'text-red-500' : 'text-black') +
					' flex items-center justify-center font-black mt-14 text-9xl'
				}
			>
				{number}
			</div>
			<div className="grow" />
			<div className="flex items-end justify-center pb-4">
				<NavLink to={'../' + to}>
					<div className="rounded-lg bg-primary px-6 py-4 text-white">{name}</div>
				</NavLink>
			</div>
		</div>
	)
}

ActiveSensors.propTypes = {
	text: PropTypes.string.isRequired,
	number: PropTypes.number.isRequired,
	link: PropTypes.shape({
		to: PropTypes.string.isRequired,
		name: PropTypes.string.isRequired,
	}).isRequired,
	isRed: PropTypes.bool,
}

export default ActiveSensors
