import { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'

export const InsideDropDown = props => {
	const {
		myPosition,
		triggerRef,
		className,
		children,
		dropdownId,
		setActiveDropdown,
	} = props

	const dropdownRef = useRef(null)

	const handleClickOutside = event => {
		let node = event.target
		while (node != null) {
			if (node.classList?.contains('isButtonActionDropdown')) {
				setActiveDropdown(dropdownId)
				return
			}
			node = node.parentNode
		}

		if (
			dropdownRef.current &&
			!dropdownRef.current.contains(event.target) &&
			!triggerRef.current.contains(event.target)
		) {
			setActiveDropdown(null)
		}
	}

	const handleKeyDown = event => {
		if (event.key === 'Escape') {
			setActiveDropdown(null)
		}
	}

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside)
		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [dropdownId, dropdownRef, setActiveDropdown, triggerRef])

	useEffect(() => {
		document.addEventListener('keydown', handleKeyDown)
		return () => {
			document.removeEventListener('keydown', handleKeyDown)
		}
	}, [])

	return (
		<div
			ref={dropdownRef}
			className={
				myPosition +
				' z-[50] absolute bg-white divide-y divide-gray-100 rounded-lg shadow-inner drop-shadow-lg w-auto dark:bg-gray-700 mt-1'
			}
		>
			<div
				className={
					'*:rounded-md px-2 *:py-2 *:px-2 my-2 text-sm min-w-24 w-max flex flex-col gap-1 *:flex *:justify-start ' +
					className
				}
			>
				{children}
			</div>
		</div>
	)
}

InsideDropDown.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
	dropdownId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
		.isRequired,
	myPosition: PropTypes.string.isRequired,
	setActiveDropdown: PropTypes.func.isRequired,
	triggerRef: PropTypes.object.isRequired,
}
