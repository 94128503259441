import PropTypes from 'prop-types'
import { NavLink, useLocation } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const TabLink = ({ to, icon, name, isHovered, showText }) => {
	const location = useLocation()
	const tenantId = location.pathname?.split('/')[2]

	return (
		<NavLink
			to={'/tenant/' + tenantId + '/' + to}
			className={({ isActive }) =>
				(isActive
					? 'text-primary *:bg-primary'
					: 'text-accent hover:text-primary') +
				' relative h-16 flex justify-start pl-4 items-center'
			}
		>
			<div className="absolute left-2 top-0 h-full w-1 rounded-full"></div>
			<FontAwesomeIcon className="ml-1.5 h-7 w-7 !bg-transparent" icon={icon} />
			{isHovered && showText && (
				<div className="mx-2 truncate !bg-transparent">{name}</div>
			)}
		</NavLink>
	)
}

TabLink.propTypes = {
	to: PropTypes.string,
	icon: PropTypes.object,
	name: PropTypes.string,
	isHovered: PropTypes.bool,
	showText: PropTypes.bool,
}

export default TabLink
