import { useEffect } from 'react'
import { getLS } from '../../../helper/LocalStorage'

const getAuthFromLS = () => {
	const authLS = getLS('auth')
	const nowUnix = Math.round(new Date().getTime() / 1000)

	if (authLS && typeof authLS === 'object' && nowUnix < authLS.expiration_time) {
		return authLS
	}

	return null
}

// Hook für die Authentifizierung
const useAuth = (LoginHandler, setLoading, systemJson) => {
	useEffect(() => {
		const authLS = getAuthFromLS()
		if (authLS) {
			LoginHandler(authLS)
		} else {
			setLoading(false)
		}
	}, [systemJson])
}

export default useAuth
