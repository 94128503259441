import { useEffect, useState } from 'react'
import { format, isSameWeek, startOfWeek } from 'date-fns'
import CustomMonthPicker from '../../../pages/app/Temperature/alarms/CustomMonthPicker'
import Dropdown from '../Dropdown/Dropdown'
import { DayPicker } from 'react-day-picker'
import TodayButton from './TodayButton'
import PropTypes from 'prop-types'
import de from 'date-fns/locale/de-AT'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	faCalendarDays,
	faChevronLeft,
	faChevronRight,
} from '@fortawesome/pro-solid-svg-icons'
import { HeaderButtons } from '../../../components/Tabs/HeaderButtons'

const customDayPickerComponents = {
	IconLeft: () => <FontAwesomeIcon icon={faChevronLeft} className="w-5 h-5" />,
	IconRight: () => <FontAwesomeIcon icon={faChevronRight} className="w-5 h-5" />,
}

const DayPickerButton = () => (
	<HeaderButtons text="Kalender">
		<div className="flex items-center justify-center w-full h-full">
			<FontAwesomeIcon icon={faCalendarDays} className="w-5 h-5" />
		</div>
	</HeaderButtons>
)

const CustomCalendar = ({ changeDateHandler, currentDate, id, today }) => {
	const [selectedDate, setSelectedDate] = useState(
		currentDate?.from || currentDate
	)
	const [isDropdownVisible, setIsDropdownVisible] = useState(false)
	const [currentMonth, setCurrentMonth] = useState(selectedDate)

	const isWeeklyView = id === 'week'

	const handleDateSelect = date => {
		if (date.getTime() === selectedDate.getTime()) return
		if (isWeeklyView && isSameWeek(date, selectedDate, { weekStartsOn: 1 }))
			return
		changeDateHandler(date)
		setIsDropdownVisible(false)
	}

	const handleWeekNumberClick = (weekNumber, dates) => {
		if (isSameWeek(dates[0], selectedDate, { weekStartsOn: 1 })) return
		const date = startOfWeek(dates[0], { weekStartsOn: 1 })
		changeDateHandler(date)
		setIsDropdownVisible(false)
	}

	useEffect(() => {
		const newDate = currentDate?.from || currentDate
		setSelectedDate(newDate)
		setCurrentMonth(newDate)
	}, [currentDate])

	return (
		<div className="print:hidden">
			<Dropdown
				dropdownId="temp_calendar"
				position="left"
				renderTrigger={DayPickerButton}
				showDropdown={isDropdownVisible}
				setShowDropdown={setIsDropdownVisible}
			>
				{id === 'alarm' ? (
					<CustomMonthPicker
						changeDateHandler={handleDateSelect}
						currentMonth={format(currentDate, 'MM')}
						currentYear={format(currentDate, 'yyyy')}
					/>
				) : (
					<DayPicker
						components={customDayPickerComponents}
						ISOWeek
						showWeekNumber={isWeeklyView}
						locale={de}
						mode="single"
						showOutsideDays
						fixedWeeks
						month={currentMonth}
						onMonthChange={month => setCurrentMonth(month)}
						onDayClick={handleDateSelect}
						onWeekNumberClick={isWeeklyView ? handleWeekNumberClick : undefined}
						modifiers={{ selected: currentDate }}
					/>
				)}
				<TodayButton today={today} handleDateSelect={handleDateSelect} />
			</Dropdown>
		</div>
	)
}

CustomCalendar.propTypes = {
	changeDateHandler: PropTypes.func.isRequired,
	currentDate: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.instanceOf(Date),
	]).isRequired,
	id: PropTypes.string.isRequired,
	today: PropTypes.string,
}

export default CustomCalendar
