import PropTypes from 'prop-types'

const Logo = ({ file, alt, className = 'h-10' }) => {
	return (
		<img
			className={className}
			src={process.env.REACT_APP_API_FILESERVER + '/img/' + file}
			alt={alt}
		/>
	)
}

Logo.propTypes = {
	file: PropTypes.string.isRequired,
	alt: PropTypes.string.isRequired,
	className: PropTypes.string,
}

export default Logo
