import { useContext, useEffect, useState } from 'react'
import {
	DataContext,
	SystemContext,
	UserContext,
} from '../../../shared/context'
import NoTenantAssigned from './NoTenantAssigned'
import Leaflet from '../../../components/LeafletMap/Leaflet'
import HomeGrid from './HomeGrid'
import ActiveSensors from './ActiveSensors'
import useLoadDevices from '../../../hooks/useLoadDevices'
import { getCurrentAlarmState } from '../../../helper/ShowData'
import ALARM_STATES from '../Temperature/alarmSettings'
import { startOfMonth } from 'date-fns'

const IS_EDITABLE = false

const Headline = (
	<div className="flex items-center justify-center w-full h-full text-4xl font-extrabold text-center">
		Meine Übersicht
	</div>
)

const panelsPos = [
	{ id: 1, col: 0, y: 0, w: 12, h: 2, transparent: true },
	{ id: 2, col: 8, y: 80, w: 4, h: 9 },
	// { id: 3, col: 0, y: 440, w: 12, h: 7 },
	{ id: 4, col: 4, y: 80, w: 4, h: 9 },
	{ id: 5, col: 0, y: 80, w: 4, h: 9 },
]

const elementPanels = ({ tenant, allDevices }) => {
	const startMonth = startOfMonth(new Date())
	const devices = allDevices
		// Hide if longer than one month away
		.filter(device => new Date(device.attributes.last_timestamp) > startMonth)
		.map(device => getCurrentAlarmState(device.attributes))
		.filter(state => state === ALARM_STATES.ALARM)
	return [
		{ id: 1, Element: Headline },
		{ id: 2, Element: <Leaflet tenants={[tenant]} /> },
		// { id: 3, Element: <NoTenantAssigned mailAddress="123" /> },
		{
			id: 4,
			Element: (
				<ActiveSensors
					number={allDevices.length}
					text="Aktive Temperatursensoren"
					link={{
						to: 'temperature',
						name: 'Zur Temperaturübersicht',
					}}
				/>
			),
		},
		{
			id: 5,
			Element: (
				<ActiveSensors
					number={devices.length}
					text="Aktive Alarme"
					link={{
						to: 'temperature/alarms',
						name: 'Zur Alarmübersicht',
					}}
					isRed={devices.length > 0}
				/>
			),
		},
	]
}

const Home = () => {
	const { systemJson } = useContext(SystemContext)
	const { token } = useContext(UserContext)
	const { tenant } = useContext(DataContext)
	const [loading, setLoading] = useState(true)
	const [allDevices, setAllDevices] = useState([])
	const loadDevices = useLoadDevices()

	useEffect(() => {
		const start = async () => {
			const filter = systemJson.defaultValues.TemperatureDeviceTypes
			const devices = await loadDevices(token, tenant.id, filter)
			setAllDevices(devices)
			setLoading(false)
		}
		start()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	if (tenant.id.toString() === process.env.REACT_APP_ADD_USER_TENANT) {
		return <NoTenantAssigned />
	}

	// TODO: Loading Screen
	if (loading) return <></>

	return (
		<div className="">
			<HomeGrid
				panelsPos={panelsPos}
				initialPanels={elementPanels({ tenant, allDevices }).map(panel => {
					const currentPanel = panelsPos.find(p => p.id === panel.id)
					return { Element: () => panel.Element, ...currentPanel }
				})}
				editable={IS_EDITABLE}
			/>
		</div>
	)
}

export default Home
