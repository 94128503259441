import { useEffect } from 'react'

const setPortraitPrint = () => {
	document.body.classList.add('portrait-print')
}

const unsetPortraitPrint = () => {
	document.body.classList.remove('portrait-print')
}

const usePortraitPrint = () => {
	useEffect(() => {
		// used to switch from default landscape print to printing in portrait
		setPortraitPrint()
		return () => unsetPortraitPrint()
	}, [setPortraitPrint, unsetPortraitPrint])
}

export default usePortraitPrint
