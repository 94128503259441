import PropTypes from 'prop-types'

const EmptyTable = ({ children }) => {
	return (
		<div className="flex flex-col items-center justify-center h-8 my-8 text-center">
			{children}
		</div>
	)
}

EmptyTable.propTypes = {
	children: PropTypes.node.isRequired,
}

export default EmptyTable
