import { useContext, useEffect, useState } from 'react'
import Search from './Search'
import ParentPage from '../../../components/Tabs/ParentPage'
import { PageContext } from './context'
import MapSearch from './map/MapSearch'
import { ensureMinDuration } from '../../../helper/Request'
import useRequest from '../../../hooks/useRequest'
import { UserContext } from '../../../shared/context'
// import { SystemContext, UserContext } from '../../../shared/context'
// import { getDeviceFilter } from '../../../helper/Filter'

const allLinks = [
	{
		to: 'tenants',
		name: 'Standortsuche',
		comp: Search,
	},
	{
		to: 'map',
		name: 'Karte',
		comp: MapSearch,
	},
	// {
	// 	to: 'alarms',
	// 	name: 'Alarme',
	// },
]

const SearchRouter = () => {
	const { token } = useContext(UserContext)
	// const { systemJson } = useContext(SystemContext)
	const [tenants, setTenants] = useState(null)
	// const [devices, setDevices] = useState(null)

	const { Request, RequestHeader } = useRequest()

	const fetchTenants = async () => {
		const fetchTenantsRequest = await ensureMinDuration(
			() =>
				Request(
					'/Tenant?pageSize=50000&sortBy=name&sortOrder=asc',
					RequestHeader(token),
					212100
				),
			200
		)
		return fetchTenantsRequest
	}
	/*
	const fetchDevices = async () => {
		const fetchDevicesRequest = await Request(
			'/Device',
			RequestHeader(
				token,
				'POST',
				getDeviceFilter(
					systemJson.defaultValues.TemperatureDeviceTypes,
				),
			),
			// TODO: ErrorCode
		)
		return fetchDevicesRequest
	}
	*/
	useEffect(() => {
		const initData = async () => {
			const allTenants = await fetchTenants()
			const allTenantsFiltered = allTenants.data.filter(
				tenant =>
					tenant?.configuration?.attributes?.customer_code !== 'APPLICATION' &&
					tenant?.configuration?.attributes?.customer_code !== 'DEMO'
			)
			setTenants(allTenantsFiltered)
			// const allDevices = await fetchDevices()
			// setDevices(allDevices.data.devices)
		}
		initData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<ParentPage
			allLinks={allLinks}
			PageContext={PageContext}
			contextObject={{ tenants }}
		/>
	)
}

export default SearchRouter
