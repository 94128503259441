import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import Logo from '../../shared/Logo'
import { Delay } from '../../helper/Request'

// TODO: Rename this Func
const LoadingPulse = ({ children, visible = true, isFullScreen = false }) => {
	const [minTime, setMinTime] = useState(false)
	const [showText, setShowText] = useState(false)

	useEffect(() => {
		const start = async () => {
			await Delay(400)
			setMinTime(true)
			await Delay(1600)
			setShowText(true)
		}
		start()
	}, [])

	if (!visible && minTime) {
		return children
	}
	return (
		<div
			className={
				isFullScreen
					? 'absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2'
					: ''
			}
		>
			<Logo
				className="h-56 animate-pulse text-logo"
				file="senshub_trans.svg"
				alt="test"
			/>
			{showText && (
				<div className="mt-4 text-xl font-bold text-center text-red-600">
					Bitte haben Sie noch etwas Geduld. Gleich wird Ihre Anfrage bearbeitet!
				</div>
			)}
		</div>
	)
}

LoadingPulse.propTypes = {
	children: PropTypes.node,
	visible: PropTypes.bool,
	isFullScreen: PropTypes.bool,
}

export default LoadingPulse
