import { useEffect, useRef } from 'react'

const useClickOutside = (isExpanded, containerRef, toggler, inputText) => {
	const latestInputText = useRef(inputText)

	useEffect(() => {
		latestInputText.current = inputText
	}, [inputText])

	const handleClickOutside = e => {
		if (containerRef.current && !containerRef.current.contains(e.target)) {
			if (latestInputText.current === '') {
				toggler()
			}
		}
	}

	useEffect(() => {
		const { addEventListener, removeEventListener } = document
		if (isExpanded) addEventListener('mousedown', handleClickOutside)
		else removeEventListener('mousedown', handleClickOutside)
		return () => removeEventListener('mousedown', handleClickOutside)
	}, [isExpanded])
}

export default useClickOutside
