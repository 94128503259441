import { useNavigate, useLocation } from 'react-router-dom'

/**
 * Navigiert unter Berücksichtigung der aktuellen Route und ersetzt den Historieneintrag.
 * @param {Object} newParams - Neue Suchparameter als Objekt.
 */
export const useReplaceSearchParams = () => {
	const navigate = useNavigate()
	const location = useLocation()

	const replaceSearchParams = newParams => {
		const searchParams = new URLSearchParams(location.search)

		Object.entries(newParams).forEach(([key, value]) => {
			searchParams.set(key, value)
		})

		navigate(
			{ pathname: location.pathname, search: searchParams.toString() },
			{ replace: true }
		)
	}

	return replaceSearchParams
}
