import { useState } from 'react'

const useDialog = () => {
	const [dialogStructure, setDialogStructure] = useState({
		header: '',
		body: '',
		footer: '',
	})
	const [openDialog, setOpenDialog] = useState(false)

	const openDialogHandler = state => {
		setOpenDialog(typeof state === 'undefined' ? !openDialog : state)
	}

	return { dialogStructure, setDialogStructure, openDialog, openDialogHandler }
}

export default useDialog
