import Logo from '../../../shared/Logo'
import PropTypes from 'prop-types'

const NoTenantAssigned = ({
	mailAddress = process.env.REACT_APP_ADD_USER_CONTACT_MAIL,
}) => {
	return (
		<div className="flex flex-col gap-4 rounded-2xl bg-white px-4 py-8 text-center">
			<div className="text-2xl font-bold">Sehr geehrter User!</div>
			<div>
				<div>
					Leider haben Sie noch keine Filiale bzw. keine Berechtigungen zugewiesen.
				</div>
				<div>
					Bitte kontaktieren Sie folgende E-Mail-Adresse:{' '}
					<a className="underline" href={'mailto:' + mailAddress}>
						{mailAddress}
					</a>
				</div>
			</div>
			<Logo file="senshub_trans.svg" />
		</div>
	)
}

NoTenantAssigned.propTypes = {
	mailAddress: PropTypes.string,
}

export default NoTenantAssigned
