import { isValid, parse, isLeapYear, getDay, format } from 'date-fns'
// import CodeHandler from './CodeHandler/CodeHandler'

const has53Weeks = year => {
	const firstDayOfYear = new Date(year, 0, 1)
	const lastDayOfYear = new Date(year, 11, 31)

	return (
		getDay(firstDayOfYear) === 4 ||
		getDay(lastDayOfYear) === 4 ||
		(getDay(firstDayOfYear) === 3 && isLeapYear(year))
	)
}

const getWeekNumber = d => {
	d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()))
	d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7))
	const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1))
	const weekNo = Math.ceil(((d - yearStart) / 86400000 + 1) / 7)
	return weekNo
}

export const getCurrentWeekYear = () => {
	const now = new Date()
	const week = getWeekNumber(now)
	const year = now.getFullYear()
	return `${week < 10 ? '0' + week : week}${year}`
}

export const getFirstDayOfWeek = input => {
	const regex = /^\d{2}\d{4}$/
	if (!regex.test(input)) {
		input = getCurrentWeekYear()
		// TODO: Add Code for "this is not a valid dateinput"
		// CodeHandler()
	}

	const week = parseInt(input.substring(0, 2), 10)
	const year = parseInt(input.substring(2, 6), 10)

	// Prüfen, ob die Woche im gültigen Bereich liegt und ob das Jahr eine 53. Woche hat
	if (week < 1 || week > 53 || (week === 53 && !has53Weeks(year))) {
		return null // Ungültige Wochenangabe oder das Jahr hat keine 53. Woche
	}

	const firstDayOfYear = new Date(year, 0, 1)
	const firstMondayOfYear = new Date(
		firstDayOfYear.setDate(
			firstDayOfYear.getDate() + ((1 - firstDayOfYear.getDay() + 7) % 7)
		)
	)
	let firstDayOfWeek = new Date(
		firstMondayOfYear.setDate(firstMondayOfYear.getDate() + (week - 1) * 7)
	)

	if (
		firstDayOfWeek.getFullYear() !== year ||
		getWeekNumber(firstDayOfWeek) !== week
	) {
		firstDayOfWeek = null
	}

	return firstDayOfWeek
}

export const formatCustomWeek = d => {
	const weekNo = getWeekNumber(d)
	const year = d.getFullYear()
	return `${weekNo < 10 ? '0' + weekNo : weekNo}${year}`
}

export const formatCustomMonth = d => {
	return format(d, 'MMyyyy')
}

export const validateDateFormat = (input, format) => {
	if (!input) return null

	const date = parse(input, format, new Date())

	if (!isValid(date)) return null

	return date
}

export const getMonthFormat = MMyyyy => {
	return parse(MMyyyy, 'MMyyyy', new Date())
}
