import { useCallback, useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useRequest from '../../../hooks/useRequest'
import { firstTenant } from './Pages'
import CodeHandler from '../../../helper/CodeHandler/CodeHandler'
import { LogoutHandler } from '../../../helper/Request'
import { saveLS } from '../../../helper/LocalStorage'
import { UserContext } from '../../../shared/context'

const useTenant = () => {
	const { token, auth } = useContext(UserContext)
	const navigate = useNavigate()
	const { Request } = useRequest()
	const [tenant, setTenant] = useState(null)

	const changeTenant = useCallback(
		async tenantId => {
			if (!auth.tenant_id.includes(Number(tenantId))) {
				navigate('/tenant/' + firstTenant(auth))
				return null
			}
			const getTenantRequest = await Request('/Tenant/' + tenantId, token)
			if (!getTenantRequest.ok) {
				CodeHandler(212101)
				LogoutHandler()
				return null
			}
			const newTenant = getTenantRequest.data
			setTenant(newTenant)
			saveLS('tenantId', newTenant.id)
		},
		[Request, token, auth.tenant_id]
	)

	return { tenant, changeTenant }
}

export default useTenant
