import PropTypes from 'prop-types'
import {
	Dialog,
	DialogHeader,
	DialogBody,
	DialogFooter,
} from '@material-tailwind/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose } from '@fortawesome/pro-solid-svg-icons'

const CustomDialog = ({ open, handler, dialogStructure }) => (
	<Dialog open={open} handler={handler} className="print:hidden">
		{dialogStructure.header && (
			<DialogHeader className="flex justify-between">
				<div>{dialogStructure.header}</div>
				<button className="cursor-pointer" onClick={() => handler(false)}>
					<FontAwesomeIcon icon={faClose} />
				</button>
			</DialogHeader>
		)}
		<DialogBody>{dialogStructure.body}</DialogBody>
		<DialogFooter>{dialogStructure.footer}</DialogFooter>
	</Dialog>
)

CustomDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	handler: PropTypes.func.isRequired,
	dialogStructure: PropTypes.shape({
		header: PropTypes.node,
		body: PropTypes.node.isRequired,
		footer: PropTypes.node,
	}).isRequired,
}

export default CustomDialog
