import { useCallback, useEffect, useRef, useState } from 'react'
import { MapContainer, Marker, Popup, TileLayer, useMap } from 'react-leaflet'
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import BgImage from '../../shared/BgImage'
import MapBackground from '../../static/map_screenshot_empty.png'
import PropTypes from 'prop-types'

const FitBounds = ({ tenants }) => {
	const map = useMap()

	useEffect(() => {
		const allBounds = []
		tenants.forEach(market => {
			if (market.coords.length === 2) {
				allBounds.push(market.coords)
			}
		})
		const bounds = L.latLngBounds(allBounds.map(bound => bound))
		map.fitBounds(bounds)
	}, [map, tenants])

	return null
}

FitBounds.propTypes = {
	tenants: PropTypes.array,
}

const customMarkerIcon = new L.Icon({
	iconUrl: 'https://unpkg.com/leaflet@1.9.4/dist/images/marker-icon.png', // Pfad zu deinem Marker-Bild
	iconSize: [25, 41], // Größe des Marker-Bildes
	iconAnchor: [12, 41], // Punkt des Icons, der der geographischen Position entspricht
	popupAnchor: [1, -34], // Punkt, von dem das Popup "wachsen" soll
})

const findCentroid = coordinates => {
	const filteredCoordinates = coordinates.filter(
		coord => coord.coords[0] && coord.coords[1]
	)
	let totalLat = 0
	let totalLng = 0
	let count = filteredCoordinates.length

	filteredCoordinates.forEach(coord => {
		totalLat += coord.coords[0]
		totalLng += coord.coords[1]
	})

	let centroidLat = totalLat / count
	let centroidLng = totalLng / count

	return [centroidLat, centroidLng]
}

const formatLatLng = attr => {
	let { latitude, longitude } = attr
	const rightFormat = el => {
		if (typeof el === 'string') {
			return Number(el)
		}
		if (typeof el === 'number') {
			return el
		}
		return undefined
	}
	latitude = rightFormat(latitude)
	longitude = rightFormat(longitude)
	if (!latitude || !longitude) {
		return []
	}
	return [latitude, longitude]
}

// TODO: #143 Change Leaflet "+" & "-" to more fitting buttons

const MapComponent = ({ tenants, fitBounds }) => {
	const [formatTenants, setFormatTenants] = useState([])
	const [noMarker, setNoMarker] = useState(false)
	const parentRefMap = useRef(null)

	const formatMarkets = useCallback(tenants => {
		// Dummy implementation for formatting markets
		return tenants.map(tenant => ({
			name: tenant.name,
			coords: formatLatLng(tenant.configuration.attributes),
		}))
	}, [])

	useEffect(() => {
		const allMarkets = formatMarkets(tenants)
		const allMarketsFiltered = allMarkets.filter(
			market => market.coords.length > 0
		)
		if (allMarketsFiltered.length > 0) {
			setFormatTenants(allMarkets)
		} else {
			setNoMarker(true)
		}
	}, [tenants, formatMarkets])

	return (
		<div ref={parentRefMap} className="h-full">
			{formatTenants.length > 0 && (
				<MapContainer
					center={findCentroid(formatTenants)}
					zoom={9}
					style={{ height: '100%' }}
					className="rounded-2xl"
				>
					<TileLayer
						url={
							'https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=' +
							process.env.REACT_APP_MAPBOX_APITOKEN
						}
					/>
					{formatTenants.map(city => {
						if (city.coords.length === 2) {
							return (
								<Marker key={city.name} position={city.coords} icon={customMarkerIcon}>
									<Popup>{city.name}</Popup>
								</Marker>
							)
						}
						return <></>
					})}
					{fitBounds && <FitBounds tenants={formatTenants} />}
				</MapContainer>
			)}
			{noMarker && (
				<BgImage src={MapBackground} alt="Blurred Map Background" blurred blackBg>
					<div className="flex flex-col text-xl font-bold text-center text-white">
						<span>Leider konnte kein</span>
						<span>Standort gefunden werden!</span>
					</div>
				</BgImage>
			)}
		</div>
	)
}

MapComponent.propTypes = {
	tenants: PropTypes.array,
	fitBounds: PropTypes.bool,
}

export default MapComponent
