export const datatableSort = (rows, selector, direction) => {
	return rows.sort((a, b) => {
		const aField = selector(a)
		const bField = selector(b)

		if (aField == null && bField == null) {
			return 0
		} else if (aField == null) {
			return direction === 'desc' ? -1 : 1
		} else if (bField == null) {
			return direction === 'desc' ? 1 : -1
		}

		const aFieldLower = aField.toString().toLowerCase()
		const bFieldLower = bField.toString().toLowerCase()

		let comparison = 0

		if (aFieldLower > bFieldLower) {
			comparison = 1
		} else if (aFieldLower < bFieldLower) {
			comparison = -1
		}

		return direction === 'desc' ? comparison * -1 : comparison
	})
}
