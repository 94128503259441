import { useContext, useState } from 'react'
import DataTable from 'react-data-table-component'
import { combineName } from '../../../../shared/format'
import { PageContext } from '../context'
import ExpandedComponent from './ExpandedComponent'
import PropTypes from 'prop-types'
import EmptyTable from '../../../../shared/EmptyTable'
import usePortraitPrint from '../../../../hooks/useProtraitPrint'
import useAbortFetch from '../../../../hooks/useAbortFetch'
import SimulateTable from '../../../../shared/SimulateTable/SimulateTable'
import expandableIcon from '../../../../shared/SimulateTable/expandableIcon'
import customStylesRows, {
	expanderButton,
} from '../../../../shared/SimulateTable/customStyles'
import ALARM_STATES, { firstColName, mapToGermanState } from '../alarmSettings'
import { getCurrentAlarmState } from '../../../../helper/ShowData'

const columns = allDevices => {
	const numberGrow = 1

	const device = id => allDevices.find(d => d.id === id)?.attributes

	const createStateColumn = state => ({
		name: mapToGermanState(state),
		selector: ([id, array]) => {
			if (!id) return ''
			return array.filter(el => el.value === state).length
		},
		sortable: true,
		grow: numberGrow,
	})

	return [
		{
			name: firstColName,
			selector: ([id]) => combineName(device(id)),
			sortable: true,
			id: 'name',
			grow: 4,
		},
		{
			name: 'Gesamt',
			selector: ([id, array]) => {
				if (!id) return ''
				return array.filter(el => el.value !== ALARM_STATES.NO_ALARM).length
			},
			sortable: true,
			grow: numberGrow,
		},
		{
			name: 'Aktueller Status',
			selector: ([id]) => mapToGermanState(getCurrentAlarmState(device(id))),
			sortable: true,
			grow: 3,
		},
		createStateColumn(ALARM_STATES.DEFROST),
		createStateColumn(ALARM_STATES.WARNING),
		createStateColumn(ALARM_STATES.ALARM),
	]
}

const Table = ({ currentMonth }) => {
	const { allDevices, getAlarmData, alarmData } = useContext(PageContext)
	const [expandedRows, setExpandedRows] = useState([])

	const handleRowExpanded = (_, [id]) => {
		if (expandedRows.includes(id)) {
			setExpandedRows(expandedRows.filter(row => row !== id))
		} else {
			setExpandedRows([...expandedRows, id])
		}
	}

	const conditionalRowStyles = [
		{
			when: ([id]) => expandedRows.includes(id),
			classNames: ['!border-b-0'],
		},
	]

	const initData = async abortController => {
		if (allDevices.length > 0) {
			const timeRange = { startDate: currentMonth, months: 1 }
			await getAlarmData(abortController, timeRange, allDevices)
		}
	}

	usePortraitPrint()
	useAbortFetch(initData, [allDevices, currentMonth])

	const finalAlarmData = alarmData
		?.map(row => {
			const [, array] = row
			const filteredLength = array.filter(
				el => el.value !== ALARM_STATES.NO_ALARM
			).length
			if (filteredLength > 0) return row
		})
		.filter(r => r)

	return (
		<div className="relative w-full h-full overflow-y-auto print:max-h-full max-h-tableHeight">
			{alarmData?.length > 0 && (
				<DataTable
					highlightOnHover
					fixedHeader={expandedRows.length === 0}
					fixedHeaderScrollHeight="calc(100vh - 17rem)"
					data={finalAlarmData}
					columns={columns(allDevices)}
					defaultSortFieldId="name"
					expandableRows
					expandableIcon={expandableIcon}
					expandableRowsComponent={ExpandedComponent}
					expandableRowsComponentProps={{ test: 123 }}
					onRowExpandToggled={handleRowExpanded}
					expandableRowExpanded={([id]) => expandedRows.includes(id)}
					onSort={() => setExpandedRows([])}
					expandOnRowClicked
					persistTableHead
					conditionalRowStyles={conditionalRowStyles}
					customStyles={{
						...customStylesRows,
						expanderButton,
						expanderRow: {
							style: {
								marginTop: 0,
							},
						},
					}}
					className="print:!overflow-hidden"
				/>
			)}
			{alarmData === null && (
				<SimulateTable
					columns={columns(allDevices)}
					dummyEntry={['', []]}
					rows={3}
					firstColName={firstColName}
					expanded
				/>
			)}
			{alarmData?.length === 0 && (
				<EmptyTable>
					<div>Es konnten keine Alarme in dieser Filiale gefunden werden!</div>
					<div>Eventuell müssen Sie den Zeitraum ändern.</div>
				</EmptyTable>
			)}
		</div>
	)
}

Table.propTypes = {
	currentMonth: PropTypes.instanceOf(Date).isRequired,
}

export default Table
