import { useState, useEffect } from 'react'

export const useIsPrintMode = () => {
	const [isPrintMode, setIsPrintMode] = useState(false)

	useEffect(() => {
		const handleBeforePrint = () => setIsPrintMode(true)

		const handleAfterPrint = () => setIsPrintMode(false)

		window.addEventListener('beforeprint', handleBeforePrint)
		window.addEventListener('afterprint', handleAfterPrint)

		return () => {
			window.removeEventListener('beforeprint', handleBeforePrint)
			window.removeEventListener('afterprint', handleAfterPrint)
		}
	}, [])

	return isPrintMode
}
