import { Route, Routes, Navigate } from 'react-router-dom'
import RedirectHandler from './RedirectHandler'
import ToRedirect from './ToRedirect'

const LoginRouter = () => (
	<Routes>
		<Route path="/login" element={<ToRedirect />} />
		<Route path="/login/sso" element={<ToRedirect allowSSO />} />
		{/* <Route path="/login/resetpassword" element={<Login />} /> */}
		<Route path="/login/target" element={<RedirectHandler />} />
		<Route path="*" element={<Navigate to="/login" />} />
	</Routes>
)

export default LoginRouter
