import { useCallback } from 'react'
import HelperRequest, { LogoutHandler, RequestHeader } from '../helper/Request'

const useRequest = () => {
	const request = useCallback(async (url, options = {}, errorCode = null) => {
		const response = await HelperRequest(url, options, errorCode, LogoutHandler)
		return response
	}, [])
	return {
		Request: request,
		RequestHeader,
	}
}

export default useRequest
