export const expanderButton = {
	style: {
		scale: '80%',
		borderRadius: '100%',
	},
}

const customStylesRows = {
	rows: { style: { paddingRight: '16px' } },
	headRow: { style: { paddingRight: '16px' } },
}

export default customStylesRows
