import {
	faBellExclamation,
	faRaindrops,
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const LegendTemperature = () => {
	return (
		<div className="flex items-center justify-end h-full gap-1 px-4 text-sm">
			<span>Legende:</span>
			<div>
				<FontAwesomeIcon icon={faRaindrops} className="mr-1 text-blue-500" />
				<span>= Abtauung</span>
				<span>, </span>
			</div>
			<div>
				<FontAwesomeIcon
					icon={faBellExclamation}
					className="mr-1 text-orange-300"
				/>
				<span>= Warnung</span>
				<span>, </span>
			</div>
			<div>
				<FontAwesomeIcon icon={faBellExclamation} className="mr-1 text-red-500" />
				<span>= Alarm</span>
			</div>
		</div>
	)
}

export default LegendTemperature
