import { useReplaceSearchParams } from '../../../../helper/UrlParams'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-solid-svg-icons'
import DisplayDate from '../DisplayDate'
import { addMonths, format, startOfMonth, subMonths } from 'date-fns'
import de from 'date-fns/locale/de-AT'
import PropTypes from 'prop-types'
import CustomCalendar from '../../../../shared/Custom/CustomCalendar/CustomCalendar'
import { HeaderButtons } from '../../../../components/Tabs/HeaderButtons'

const germanDate = date => format(date, 'MMMM yyyy', { locale: de })

const Header = ({ currentMonth }) => {
	const replaceSearchParams = useReplaceSearchParams()

	const changeDateHandler = date => {
		if (date.getTime() === currentMonth.getTime()) return
		const newDate = format(startOfMonth(date), 'MMyyyy')
		replaceSearchParams({ month: newDate })
	}

	const prevMonth = () => {
		const newDate = format(subMonths(currentMonth, 1), 'MMyyyy')
		replaceSearchParams({ month: newDate })
	}
	const nextMonth = () => {
		const newDate = format(addMonths(currentMonth, 1), 'MMyyyy')
		replaceSearchParams({ month: newDate })
	}

	return (
		<>
			<div className="flex h-full gap-0.5">
				<CustomCalendar
					id="alarm"
					changeDateHandler={changeDateHandler}
					currentDate={currentMonth}
					today="Aktuelles Monat"
				/>
				<HeaderButtons onClick={prevMonth}>
					<FontAwesomeIcon icon={faChevronLeft} className="w-5 h-5" />
				</HeaderButtons>
				<HeaderButtons onClick={nextMonth}>
					<FontAwesomeIcon icon={faChevronRight} className="w-5 h-5" />
				</HeaderButtons>
			</div>
			<DisplayDate>{germanDate(currentMonth)}</DisplayDate>
		</>
	)
}

Header.propTypes = {
	currentMonth: PropTypes.instanceOf(Date).isRequired,
}

export default Header
