import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ALARM_STATES, { colorAlarm } from './alarmSettings'

const hourCell = hour => ({
	name: <CustomCell value={{ temp: hour }} />,
	minWidth: '42px',
	cell: row => <CustomCell value={row.values['hour_' + hour]} tabNums />,
	center: 'true',
	grow: 1,
	compact: 'true',
})

const CustomCell = ({ value, tabNums }) => {
	const [alarmProps, setAlarmProps] = useState({ color: '', icon: null })

	const alarm = value?.alarm || null
	let temp = value?.temp

	useEffect(() => {
		setAlarmProps(colorAlarm(alarm))
	}, [alarm])

	return (
		<div
			className={
				alarmProps.color +
				(tabNums ? 'tabular-nums ' : '') +
				'grid justify-end items-center w-full pr-1'
			}
		>
			{alarm && alarm !== ALARM_STATES.NO_ALARM && (
				<div className="flex items-end justify-center w-full h-4">
					{alarmProps?.icon && <FontAwesomeIcon icon={alarmProps?.icon} size="lg" />}
				</div>
			)}
			<div>{temp}</div>
		</div>
	)
}

CustomCell.propTypes = {
	value: PropTypes.object,
	tabNums: PropTypes.bool,
}

export default hourCell
