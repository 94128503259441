import { useContext, useEffect, useState } from 'react'
import { getLS } from '../../../helper/LocalStorage'
import { NavLink } from 'react-router-dom'
import DataTableComponent from './DataTableSearch'
import allColumns from './allColumns.json'
import ContentPage, {
	BottomPage,
	HeaderPage,
} from '../../../components/Tabs/ContentPage'
import { PageContext } from './context'
import { SystemContext } from '../../../shared/context'
import { datatableSort } from '../../../helper/Sort'
import SimulateTable from '../../../shared/SimulateTable/SimulateTable'
import EmptyTable from '../../../shared/EmptyTable'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/pro-solid-svg-icons'
import { HeaderButtons } from '../../../components/Tabs/HeaderButtons'

const getObjectValueByPath = (obj, path) => {
	const keys = path.split('.')
	let result = obj
	for (const key of keys) {
		if (result == null) return undefined
		result = result[key]
	}
	return result
}

const allColumnsWithSelector = allColumns.map(col => {
	const thisColumn = { ...col }
	thisColumn.cell = cell => (
		<NavLink
			to={'/tenant/' + cell.id}
			className="flex items-center justify-start w-full h-full"
		>
			{getObjectValueByPath(cell, thisColumn.pathSelector)}
		</NavLink>
	)
	if (thisColumn.sortable) {
		thisColumn.sortFunction = (rowA, rowB) =>
			datatableSort(
				[rowA, rowB],
				x => getObjectValueByPath(x, thisColumn.pathSelector),
				'asc'
			)[0] === rowA
				? -1
				: 1
	}
	return thisColumn
})

const filteredData = (filterText, tenants) => {
	if (tenants?.length > 0) {
		const propertiesToFilter = allColumnsWithSelector
			.map(col => col.id)
			.filter(col => col)

		const filtered = tenants.filter(tenant => {
			for (let property of propertiesToFilter) {
				let myItemProperty =
					tenant[property] ?? tenant.configuration?.attributes[property]

				if (typeof myItemProperty === 'object' && myItemProperty !== null) {
					if (myItemProperty.name) {
						myItemProperty = myItemProperty.name
					} else {
						return false
					}
				}

				if (
					typeof myItemProperty === 'string' &&
					myItemProperty.toLowerCase().includes(filterText.toLowerCase())
				) {
					return true
				}
			}
			return false
		})
		return filtered
	}
}

const Search = () => {
	const { systemJson } = useContext(SystemContext)
	const { tenants } = useContext(PageContext)
	const [columns, setColumns] = useState([])
	const [filterText, setFilterText] = useState('')
	const [resetPaginationToggle, setResetPaginationToggle] = useState(false)
	const [dataTableData, setDataTableData] = useState(null)

	const [isExpandedInput, setIsExpandedInput] = useState(false)

	useEffect(() => {
		if (tenants?.length > 0) {
			const tenantSearchLS = getLS('tenantSearch_sort')
			let sortedColumns
			if (tenantSearchLS) {
				sortedColumns = tenantSearchLS.map(key => {
					return allColumnsWithSelector.find(column => column.id === key)
				})
			} else {
				sortedColumns = allColumnsWithSelector.filter(col => col.defaultShow)
			}

			setColumns(sortedColumns)
		}
	}, [tenants])

	useEffect(() => {
		if (tenants?.length > 0) {
			const filteredItems = filteredData(filterText, tenants)

			const finalItems = filteredItems.filter(
				tenant => !systemJson.forbiddenTenantsInSearch.includes(tenant.id)
			)
			setDataTableData(finalItems)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filterText, tenants])

	const handleClear = () => {
		if (filterText) {
			setResetPaginationToggle(!resetPaginationToggle)
			setFilterText('')
		}
	}

	const setFilterHandler = e => setFilterText(e.target.value)

	const handleExpandedInput = () => setIsExpandedInput(!isExpandedInput)

	return (
		<>
			<HeaderPage>
				<HeaderButtons
					text="Suche"
					onClick={handleExpandedInput}
					toInput
					inputText={filterText}
					onInputChange={setFilterHandler}
					onInputClear={handleClear}
				>
					<FontAwesomeIcon icon={faSearch} className="w-5 h-5" />
				</HeaderButtons>
			</HeaderPage>
			<ContentPage>
				{dataTableData?.length > 0 && (
					<DataTableComponent columns={columns} data={dataTableData} />
				)}
				{dataTableData === null && (
					<SimulateTable columns={columns} rows={10} addBottomPage />
				)}
				{dataTableData?.length === 0 && (
					<EmptyTable>Es konnte keine Filiale gefunden werden!</EmptyTable>
				)}
			</ContentPage>
			{dataTableData?.length === 0 && <BottomPage />}
		</>
	)
}

export default Search
