import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-solid-svg-icons'
import { addDays, format, subDays } from 'date-fns'

import de from 'date-fns/locale/de-AT'
import DisplayDate from '../DisplayDate'
import { useReplaceSearchParams } from '../../../../helper/UrlParams'
import PropTypes from 'prop-types'
import CustomCalendar from '../../../../shared/Custom/CustomCalendar/CustomCalendar'
import { HeaderButtons } from '../../../../components/Tabs/HeaderButtons'

const Header = ({ currentDate, changeDateHandler }) => {
	const replaceSearchParams = useReplaceSearchParams()

	const germanDate = date => format(date, 'dd. MMMM yyyy', { locale: de })

	const prevDay = () => {
		const newDate = format(subDays(currentDate, 1), 'yyyyMMdd')
		replaceSearchParams({ date: newDate })
	}
	const nextDay = () => {
		const newDate = format(addDays(currentDate, 1), 'yyyyMMdd')
		replaceSearchParams({ date: newDate })
	}

	return (
		<>
			<div className="flex h-full gap-0.5">
				{/* FUTURE: Add more filter possibilities */}
				{/* <HeaderButtons>
						<FontAwesomeIcon icon={faSliders} className="w-5 h-5" />
					</HeaderButtons> */}

				<CustomCalendar
					id="day"
					changeDateHandler={changeDateHandler}
					currentDate={currentDate}
					today="Heute"
				/>

				<HeaderButtons onClick={prevDay}>
					<FontAwesomeIcon icon={faChevronLeft} className="w-5 h-5" />
				</HeaderButtons>
				<HeaderButtons onClick={nextDay}>
					<FontAwesomeIcon icon={faChevronRight} className="w-5 h-5" />
				</HeaderButtons>
			</div>
			<DisplayDate>{germanDate(currentDate)}</DisplayDate>
		</>
	)
}

Header.propTypes = {
	currentDate: PropTypes.instanceOf(Date).isRequired,
	changeDateHandler: PropTypes.func.isRequired,
}

export default Header
