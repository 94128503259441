import { toast } from 'react-toastify'
import AllCodes from './AllCodes'

// First digit is the type of the toast.
// Second and third digits are for the topic, where the error occoured.
// 4. - 6. stands for the excat error.

export const CodeReader = (code, params) => {
	code = code.toString()

	let myToast = null,
		myCode = null

	// 1xxxxx - Error
	if (code.charAt(0) === '1') {
		myToast = text => toast.success(text)
		myCode = AllCodes.AllCodesSuccess(code, params)
	}

	// 2xxxxx - Success
	if (code.charAt(0) === '2') {
		myToast = text => toast.error(text)
		myCode = AllCodes.AllCodesError(code, params)
	}

	if (myCode) {
		myToast(myCode)
	} else {
		// If code is not defined
		return CodeReader(210100)
	}
}

const CodeHandler = (code, params, regexLength = 6) => {
	// If code is not a number
	if (typeof code !== 'number') {
		CodeReader(210100)
		return
	}

	// Checks if code has 'regexLength' digits and is a number
	const regex = new RegExp(`^\\d{${regexLength}}$`)
	if (!regex.test(code.toString())) {
		CodeReader(210101, { code: code, maxLength: regexLength })
		return
	}

	// Default error handling
	CodeReader(code, params)
}

export default CodeHandler
