/* eslint-disable tailwindcss/no-custom-classname */
import PropTypes from 'prop-types'

import { useState } from 'react'
import { eachMonthOfInterval, format, startOfYear, endOfYear } from 'date-fns'
import de from 'date-fns/locale/de-AT'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-solid-svg-icons'
import { getMonthFormat } from '../../../../helper/ValidateFormat'

const allMonths = eachMonthOfInterval({
	start: startOfYear(new Date()),
	end: endOfYear(new Date()),
})

const CustomMonthPicker = ({
	currentMonth,
	currentYear,
	changeDateHandler,
}) => {
	const [year, setYear] = useState(Number(currentYear))
	const prevYear = () => setYear(year - 1)
	const nextYear = () => setYear(year + 1)

	const isToday = monthIndex => {
		const formatIndex = monthIndex.toString().padStart(2, '0')
		const thisMonth = format(new Date(), 'MM')
		const thisYear = format(new Date(), 'yyyy')

		const isSameMonth = thisMonth === formatIndex
		const isSameYear = year.toString() === thisYear
		if (isSameMonth && isSameYear) return 'rdp-day_today '
		return ''
	}

	const isSelected = monthIndex => {
		const formatIndex = monthIndex.toString().padStart(2, '0')

		const isSameMonth = currentMonth.toString() === formatIndex
		const isSameYear = currentYear.toString() === year.toString()

		if (isSameMonth && isSameYear) return 'rdp-day_selected '
		return ''
	}

	const changeMonth = (monthIndex, year) => {
		const newDate = monthIndex.toString().padStart(2, '0') + year
		changeDateHandler(getMonthFormat(newDate))
	}

	return (
		<div className="flex flex-col rdp">
			<div className="rdp-caption">
				<div
					className="rdp-caption_label"
					aria-live="polite"
					id="react-day-picker-3"
				>
					{year}
				</div>
				<div className="rdp-nav">
					<button
						name="previous-year"
						aria-label="Go to previous year"
						className="rdp-button_reset rdp-button rdp-nav_button rdp-nav_button_previous"
						type="button"
						onClick={prevYear}
					>
						<FontAwesomeIcon icon={faChevronLeft} className="w-5 h-5" />
					</button>
					<button
						name="next-year"
						aria-label="Go to next year"
						className="rdp-button_reset rdp-button rdp-nav_button rdp-nav_button_next"
						type="button"
						onClick={nextYear}
					>
						<FontAwesomeIcon icon={faChevronRight} className="w-5 h-5" />
					</button>
				</div>
			</div>
			<div className="grid grid-cols-3 grid-rows-4 gap-1 mt-1">
				{allMonths.map((month, i) => (
					<button
						key={'cal_month_' + i}
						className={
							isSelected(i + 1) +
							isToday(i + 1) +
							'rdp-button rdp-button_reset !px-4 !py-1.5 text-center rounded-lg'
						}
						onClick={() => changeMonth(i + 1, year)}
					>
						{format(month, 'MMM', { locale: de })}
					</button>
				))}
			</div>
		</div>
	)
}

CustomMonthPicker.propTypes = {
	currentMonth: PropTypes.string.isRequired,
	currentYear: PropTypes.string.isRequired,
	changeDateHandler: PropTypes.func.isRequired,
}

export default CustomMonthPicker
