import { useContext, useEffect, useState } from 'react'
import { UserContext } from '../../../shared/context'
import { getLS } from '../../../helper/LocalStorage'
import { firstTenant } from './Pages'
import CodeHandler from '../../../helper/CodeHandler/CodeHandler'
import { Navigate } from 'react-router-dom'

const NoTenantSelected = () => {
	const { auth } = useContext(UserContext)
	const [lastTenantId, setLastTenantId] = useState(null)

	useEffect(() => {
		const LSTenantId = getLS('tenantId')
		if (LSTenantId) {
			setLastTenantId(LSTenantId)
		} else if (auth.tenant_id.length > 0) {
			setLastTenantId(firstTenant(auth))
		} else {
			CodeHandler(212102)
		}
	}, [auth])

	return lastTenantId && <Navigate replace to={'/tenant/' + lastTenantId} />
}

export default NoTenantSelected
