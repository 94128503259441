import { useContext, useEffect, useState } from 'react'
import ContentPage, {
	BottomPage,
	HeaderPage,
} from '../../../../components/Tabs/ContentPage'
import Leaflet from '../../../../components/LeafletMap/Leaflet'
import { PageContext } from '../context'

const MapSearch = () => {
	const { tenants, parentPageRef } = useContext(PageContext)
	const [loading, setLoading] = useState(true)

	useEffect(() => {
		parentPageRef.current.classList.add('flex-grow')
		setLoading(false)
	}, [parentPageRef])

	// TODO: If map is loaded firstly, the map is not loading use-friendly
	if (!tenants || loading) {
		return <></>
	}

	return (
		<>
			<HeaderPage />
			<ContentPage stretch>
				<div className="h-full p-4">
					<Leaflet tenants={tenants} fitBounds />
				</div>
			</ContentPage>
			<BottomPage />
		</>
	)
}

export default MapSearch
