// hooks/useLoginHandler.js
import { useState } from 'react'
import useRequest from '../../../hooks/useRequest'
import { LogoutHandler } from '../../../helper/Request'
import { rolesSplited } from '../../../helper/ShowData'
import { saveLS } from '../../../helper/LocalStorage'

const useLoginHandler = forbiddenTenantsInSearch => {
	const [auth, setAuth] = useState(null)
	const [profile, setProfile] = useState(null)
	const [loading, setLoading] = useState(true)
	const { Request } = useRequest()

	const getProfile = async auth => {
		const getProfileRequest = await Request('/Users/Profile', auth.access_token)
		if (!getProfileRequest.ok) {
			LogoutHandler()
			return
		}

		let profile = getProfileRequest.data
		profile.rolesSplited = rolesSplited(profile.roles)

		if (profile.username === 'OAuth2') {
			LogoutHandler()
			return
		}

		auth = {
			...auth,
			tenant_id: auth.tenant_id.filter(
				id => !forbiddenTenantsInSearch.includes(id)
			),
		}

		saveLS('auth', auth)
		setAuth(auth)
		setProfile(profile)
		setLoading(false)
	}

	const LoginHandler = async auth => {
		await getProfile(auth)
	}

	return {
		auth,
		profile,
		loading,
		LoginHandler,
		setLoading,
	}
}

export default useLoginHandler
