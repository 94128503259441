import PropTypes from 'prop-types'

export const HeaderPage = ({ children, Export }) => {
	return (
		<div className="flex items-center justify-between px-4 h-14">
			<div className="flex items-center gap-4">{children}</div>
			<div>{Export}</div>
		</div>
	)
}

HeaderPage.propTypes = {
	children: PropTypes.node.isRequired,
	Export: PropTypes.node,
}

export const ContentPage = ({ children, stretch }) => {
	return (
		<div
			className={(stretch ? 'flex-grow ' : '') + 'border-t-2 border-background'}
		>
			{children}
		</div>
	)
}

ContentPage.propTypes = {
	children: PropTypes.node.isRequired,
	stretch: PropTypes.bool,
}

export const BottomPage = ({ children }) => {
	return <div className="border-t-2 h-14 border-background">{children}</div>
}

BottomPage.propTypes = {
	children: PropTypes.node,
}

export default ContentPage
