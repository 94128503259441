import {
	faHouse,
	faMagnifyingGlass,
	faTemperatureHalf,
} from '@fortawesome/pro-solid-svg-icons'

import SearchRouter from '../Search/Router'
import Home from '../Home/Home'
import TemperatureRouter from '../Temperature/Router'

const showUsecase = (usecaseAttributes, roleName) => {
	const { rolesUsecase, isTenantAddUser } = usecaseAttributes

	if (isTenantAddUser) return false

	const filteredRoles = rolesUsecase.roles.filter(
		usecase => usecase === roleName
	)
	return rolesUsecase.all || filteredRoles.length > 0
}

const AllLinksArray = (moreThanOneTenant, usecaseAttributes) => {
	return [
		{
			to: 'home',
			name: 'Übersicht',
			icon: faHouse,
			show: true,
			element: Home,
			subPaths: false,
		},
		{
			to: 'search',
			name: 'Suche',
			icon: faMagnifyingGlass,
			show: moreThanOneTenant,
			element: SearchRouter,
			subPaths: true,
		},
		// { to: 'devices', name: 'Alle Geräte', icon: faLaptopMobile },
		{
			to: 'temperature',
			name: 'Temperaturübersicht',
			icon: faTemperatureHalf,
			show: showUsecase(usecaseAttributes, 'Temperature/IO'),
			element: TemperatureRouter,
			subPaths: true,
		},
		// {
		// 	to: 'electricity',
		// 	name: 'Energiemanagement',
		// 	icon: faBolt,
		// },
	]
}

export default AllLinksArray
