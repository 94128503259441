/**
 * Get the value from Local Storage for a given key
 *
 * @param {String} key - The key of the value to retrieve
 * @returns {String|Array|Object|null} - The value associated with the given key, or null if not found
 */
export const getLS = key => {
	const newItem = localStorage.getItem(key)
	try {
		const parsed = JSON.parse(newItem)
		return parsed
	} catch (error) {
		// If JSON.parse is unsuccessful (is a string), return the string.
		return newItem
	}
}

/**
 * Save a value to Local Storage for a given key
 *
 * @param {String} key - The key to associate with the value
 * @param {*} value - The value to save to Local Storage
 * @returns {void}
 */
export const saveLS = (key, value) => {
	const newValue = typeof value === 'string' ? value : JSON.stringify(value)
	localStorage.setItem(key, newValue)
}

/**
 * Remove a value from Local Storage for a given key
 *
 * @param {String} key - The key of the value to remove
 * @returns {void}
 */
export const removeLS = key => localStorage.removeItem(key)
