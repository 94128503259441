import CodeHandler from './CodeHandler/CodeHandler'

const AUTH_ERROR_CODE = 211101
const OTHER_ERROR_CODE = 210105

export const Delay = async ms => {
	return new Promise(resolve => setTimeout(resolve, ms))
}

export const ensureMinDuration = async (asyncFunc, minDuration) => {
	const startTime = new Date().getTime()
	const result = await asyncFunc()
	const endTime = new Date().getTime()
	const timeDiff = endTime - startTime
	if (timeDiff < minDuration) await Delay(minDuration - timeDiff)
	return result
}

export const LogoutHandler = () => {
	window.location.href =
		process.env.REACT_APP_CLIENT +
		'/logout?post_logout_redirect_uri=' +
		window.location.origin +
		'/logout'
}

export const RequestHeader = (
	bearer,
	method = 'GET',
	data = null,
	abortController = null,
	contentType = 'application/json',
	headers = {}
) => {
	const myHeader = { headers, method }

	if (bearer) myHeader.headers.Authorization = bearer

	if (method === 'POST' || method === 'PUT' || method === 'PATCH') {
		myHeader.headers['Content-Type'] = contentType

		if (data) myHeader.body = JSON.stringify(data)
	}

	if (abortController) myHeader.signal = abortController.signal

	return myHeader
}

const processResponse = async response => {
	const status = response.status
	let data = null
	if (status !== 204) data = await response.json()
	return { status, data }
}

const handleAuthError = (url, status, LogoutHandler) => {
	const auth = process.env.REACT_APP_API_COMMONAPI + '/Authentication/'
	if (status === 401 && !url.startsWith(auth)) {
		CodeHandler(AUTH_ERROR_CODE)
		LogoutHandler()
	}
}

const handleFetchError = (error, status, url, errorCode) => {
	const errorObject = { ok: false, status, url, data: error }

	if (status === 200) return { ok: true, data: error }

	if (error.name !== 'AbortError') {
		if (!status) CodeHandler(OTHER_ERROR_CODE)
		else if (errorCode) CodeHandler(errorCode)
	}

	return errorObject
}

const HelperRequest = async (url, options, errorCode, LogoutHandler) => {
	let status = null
	const controller = new AbortController()
	const signal = options.signal || controller.signal

	if (url.startsWith('/')) url = process.env.REACT_APP_API_COMMONAPI + url

	if (typeof options === 'string') options = RequestHeader(options)

	try {
		const response = await fetch(url, { ...options, signal })

		const { status: responseStatus, data } = await processResponse(response)
		status = responseStatus

		handleAuthError(url, status, LogoutHandler)

		const successStatusArray = [200, 201, 204]
		if (!successStatusArray.includes(status)) throw data

		return { ok: true, data }
	} catch (error) {
		return handleFetchError(error, status, url, errorCode)
	}
}

export default HelperRequest
