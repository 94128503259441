const Configurations = {
	currentDay: [
		{ name: 'Installationsort', value: row => row.device.name },
		{
			name: 'Temperatur Soll',
			value: row => row.device.minMax.target.string,
		},
	],
	currentWeek: [{ name: 'Tag', value: row => row.day }],
}

const createCSV = async (array, name) => {
	const columnDelimiter = ','
	const lineDelimiter = '\n'

	const csvContent = array
		.map(row => row.join(columnDelimiter))
		.join(lineDelimiter)

	const blob = new Blob(['\ufeff' + csvContent], {
		type: 'text/csv;charset=utf-8;',
	})
	const url = window.URL.createObjectURL(blob)

	const link = document.createElement('a')
	link.setAttribute('href', url)
	link.setAttribute('download', name + '.csv')
	link.style.display = 'none'

	document.body.appendChild(link)
	link.click()

	document.body.removeChild(link)
	window.URL.revokeObjectURL(url)

	return true
}

// TODO: Future: if needed make this more generic (not only for temperature)
const downloadCSV = async (array, current, name, csvValue) => {
	if (!current) return false

	const myArray = [
		Configurations[current]
			.map(item => item.name)
			.concat(Array.from({ length: 24 }, (_, i) => i.toString())),
	]

	array.forEach(row => {
		const rowData = Configurations[current]
			.map(item => item.value(row))
			.concat(
				Array.from(
					{ length: 24 },
					(_, i) => row.values['hour_' + i][csvValue] || null
				)
			)
		myArray.push(rowData)
	})

	const myCSV = await createCSV(myArray, name)

	return myCSV
}
export default downloadCSV
