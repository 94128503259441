import { useCallback } from 'react'
import useRequest from './useRequest'
import { ensureMinDuration } from '../helper/Request'

const useLoadTimeseries = () => {
	const { Request, RequestHeader } = useRequest()

	const loadTimeseries = useCallback(
		async (
			token,
			timerange,
			{ deviceId, tenantId },
			measuredValue,
			abortController,
			aggregation,
			simulateLoadingTime = 200
		) => {
			const { from, to } = timerange
			const body = {
				from,
				to,
				tags: { deviceId },
				fields: measuredValue,
				aggregation,
			}

			const timeseriesRequest = await ensureMinDuration(
				() =>
					Request(
						`${process.env.REACT_APP_API_TIMESERIES}/TimeSeriesData/getAdvanced?tenantId=${tenantId}`,
						RequestHeader(token, 'POST', body, abortController)
					),
				simulateLoadingTime
			)

			return timeseriesRequest
		},
		[Request, RequestHeader]
	)

	return loadTimeseries
}

export default useLoadTimeseries
