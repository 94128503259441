import { useContext, useState } from 'react'
import Dropdown from '../../../shared/Custom/Dropdown/Dropdown'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/pro-solid-svg-icons'
import { DataContext } from '../../../shared/context'
import { PageContext } from './context'
import downloadCSV from '../../../helper/CSVExport'
import CodeHandler from '../../../helper/CodeHandler/CodeHandler'
import { format, getISOWeek, getYear } from 'date-fns'
import PropTypes from 'prop-types'
import { HeaderButtons } from '../../../components/Tabs/HeaderButtons'

const InsideDropDown = () => (
	<HeaderButtons className="text-right" text="Download">
		<FontAwesomeIcon icon={faDownload} className="w-5 h-5" />
	</HeaderButtons>
)

const TemperatureExport = ({
	currentDay,
	currentWeek,
	// currentMonth,
	disableCSV,
}) => {
	const { tenant } = useContext(DataContext)
	const { temperatureData } = useContext(PageContext)
	const [showDropdown, setShowDropdown] = useState(false)

	const generatePDF = () => {
		setShowDropdown(false)
		setTimeout(() => {
			window.print()
		}, 300)
	}

	const allCSVExports = async () => {
		let currentTab
		let myName = tenant.configuration.attributes.market_id

		if (currentDay) {
			myName = format(currentDay, 'yyyy_MM_dd') + '_' + myName
			currentTab = 'currentDay'
		} else if (currentWeek) {
			const isoWeek = getISOWeek(currentWeek)
			const year = getYear(currentWeek)
			myName = `KW${isoWeek.toString().padStart(2, '0')}_${year}_${myName}`
			currentTab = 'currentWeek'
		}

		const tempDownload = await downloadCSV(
			temperatureData,
			currentTab,
			myName + '_temperature',
			'temp'
		)
		const alarmDownload = await downloadCSV(
			temperatureData,
			currentTab,
			myName + '_alarmStatus',
			'alarm'
		)

		if (alarmDownload && tempDownload) {
			CodeHandler(115100)
		} else {
			CodeHandler(215100)
		}
	}

	return (
		<Dropdown
			dropdownId="download"
			position="right"
			renderTrigger={InsideDropDown}
			showDropdown={showDropdown}
			setShowDropdown={setShowDropdown}
		>
			{!disableCSV && (
				<button
					onClick={allCSVExports}
					className="cursor-pointer hover:bg-gray-100"
				>
					Download CSV
				</button>
			)}
			<button onClick={generatePDF} className="cursor-pointer hover:bg-gray-100">
				Download PDF
			</button>
		</Dropdown>
	)
}

TemperatureExport.propTypes = {
	currentDay: PropTypes.instanceOf(Date),
	currentWeek: PropTypes.instanceOf(Date),
	// currentMonth: PropTypes.instanceOf(Date),
	disableCSV: PropTypes.bool,
}

export default TemperatureExport
