import { useState } from 'react'
import ToastNotification from './ToastNotification'
import CustomDialog from './CustomDialog'
import ContextsProvider from './ContextsProvider'
import useLoginHandler from './hooks/useLoginHandler'
import useDialog from './hooks/useDialog'
import useAuth from './hooks/useAuth'
import useFetchSystemJson from './hooks/useFetchSystemJson'
import LoadingPulse from '../../components/Loading/LoadingPulse'
import Pages from '../app/Pages/Pages'
import LoginRouter from '../Login/LoginRouter'

// TODO: Get from Fileserver
const forbiddenTenantsInSearch = [447, 947]

const Router = () => {
	const [systemJson, setSystemJson] = useState({})
	const [activeDropdown, setActiveDropdown] = useState(null)

	const { auth, profile, loading, LoginHandler, setLoading } = useLoginHandler(
		forbiddenTenantsInSearch
	)
	const { dialogStructure, setDialogStructure, openDialog, openDialogHandler } =
		useDialog()

	useAuth(LoginHandler, setLoading, systemJson)
	useFetchSystemJson(setSystemJson, forbiddenTenantsInSearch)

	return (
		<ContextsProvider
			systemContext={{
				systemJson,
				setDialogStructure,
				openDialogHandler,
				dropdownState: [activeDropdown, setActiveDropdown],
				LoginHandler,
			}}
			userContext={{ profile, auth, token: auth?.access_token }}
		>
			<LoadingPulse isFullScreen visible={loading} className="items-center w-full">
				<>{auth ? <Pages /> : <LoginRouter />}</>
			</LoadingPulse>
			<ToastNotification />
			<CustomDialog
				open={openDialog}
				handler={openDialogHandler}
				dialogStructure={dialogStructure}
			/>
		</ContextsProvider>
	)
}

export default Router
