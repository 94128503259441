import { useContext } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import { DataContext, JsonContext, UserContext } from '../../../shared/context'

import Sidebar from '../../../components/Sidebar/Sidebar'
import Header from '../../../components/Header/Header'

import AppRouter from '../Router'

import AllLinksArray from './AllLinks'
import useTenant from './useTenant'
import NoTenantSelected from './NoTenantSelected'

export const firstTenant = auth => auth.tenant_id[0] || [tenantAddUser]

const tenantAddUser = Number(process.env.REACT_APP_ADD_USER_TENANT)

const Pages = () => {
	const { profile, auth } = useContext(UserContext)
	const { tenant, changeTenant } = useTenant()
	const moreThanOneTenant = auth.tenant_id.length > 1

	const isTenantAddUser = (tenant?.id || tenantAddUser) === tenantAddUser
	const rolesUsecase = profile.rolesSplited.usecases
	const usecaseAttributes = { rolesUsecase, isTenantAddUser }

	return (
		<JsonContext.Provider
			value={{
				AllLinks: AllLinksArray(moreThanOneTenant, usecaseAttributes),
				moreThanOneTenant,
			}}
		>
			<DataContext.Provider value={{ tenant, changeTenant }}>
				{tenant && <Sidebar />}
				<div className="flex flex-col overflow-auto grow print:overflow-visible">
					{tenant && <Header tenant={tenant} />}
					<div className="flex flex-col w-full p-5 grow">
						<Routes>
							<Route path="/" element={<NoTenantSelected />} />
							<Route path="/tenant/:tenantId/*" element={<AppRouter />} />
							<Route path="*" element={<Navigate to="/" replace />} />
						</Routes>
					</div>
				</div>
			</DataContext.Provider>
		</JsonContext.Provider>
	)
}

export default Pages
