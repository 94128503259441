export const fetchFileServer = async (
	Request,
	path = '',
	errorCode = 215101
) => {
	const getFileServerRequest = await Request(
		process.env.REACT_APP_API_FILESERVER + '/' + path,
		{ cache: 'no-store' },
		errorCode
	)

	return getFileServerRequest.data || null
}
