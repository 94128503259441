import {
	faBellExclamation,
	faRaindrops,
} from '@fortawesome/pro-solid-svg-icons'

const ALARM_STATES = {
	NO_ALARM: 'no_alarm',
	DEFROST: 'defrost_status',
	WARNING: 'warning_status',
	ALARM: 'alarm_status',
}

export const colorAlarm = alarm => {
	switch (alarm) {
		case ALARM_STATES.ALARM:
			return { color: 'text-red-500 ', icon: faBellExclamation }
		case ALARM_STATES.WARNING:
			return { color: 'text-orange-300 ', icon: faBellExclamation }
		case ALARM_STATES.DEFROST:
			return { color: 'text-blue-500 ', icon: faRaindrops }
		default:
			return { color: '' }
	}
}

export const timeseriesSettings = {
	temp: {
		dataTypes: ['temperature'],
		aggregation: { every: '1h', fn: 'mean' },
	},
	alarms: {
		dataTypes: [ALARM_STATES.DEFROST, ALARM_STATES.WARNING, ALARM_STATES.ALARM],
		aggregation: { every: '1h', fn: 'max' },
		delay: 500,
	},
}

export const mapToGermanState = state => {
	switch (state) {
		case undefined:
		case null:
			return ''
		case ALARM_STATES.NO_ALARM:
			return 'Kein Alarm'
		case ALARM_STATES.DEFROST:
			return 'Abtauung'
		case ALARM_STATES.WARNING:
			return 'Warnung'
		case ALARM_STATES.ALARM:
			return 'Alarm'
		default:
			return 'Unbekannt'
	}
}

export const firstColName = 'Installationsort'

export default ALARM_STATES
