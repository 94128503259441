import { useContext } from 'react'
import { PageContext } from '../context'
import DataTable from 'react-data-table-component'
import PropTypes from 'prop-types'
import { format } from 'date-fns'
import { combineName } from '../../../../shared/format'
import { firstColName, mapToGermanState } from '../alarmSettings'

export const formatTimestamp = d => {
	const timestamp = new Date(d)
	const formattedDate = format(timestamp, 'dd.MM.yyyy, HH') + ':00 Uhr'
	return formattedDate
}

const columns = device => {
	const finalArray = [
		{
			name: firstColName,
			selector: () => combineName(device),
			id: 'name',
		},
		{
			name: 'Zeitstempel',
			selector: row => formatTimestamp(row.timestamp),
			sortable: true,
			id: 'timestamp',
		},
		{
			name: 'Status',
			selector: row => mapToGermanState(row.value),
			sortable: true,
			id: 'status',
		},
	]
	return finalArray
}

const currentDeviceAttributes = (id, allDevices) => {
	return allDevices.find(d => d.id === id)?.attributes
}
// TODO: Add visualisation graph like in Grafana

const ExpandedComponent = ({ data }) => {
	const { allDevices } = useContext(PageContext)

	const [id, array] = data

	return (
		<div className="border-l-8 border-y border-y-tableBorder">
			<DataTable
				highlightOnHover
				expandableRows
				expandableRowDisabled={() => true}
				expandableIcon={<></>}
				data={array}
				columns={columns(currentDeviceAttributes(id, allDevices))}
				customStyles={{
					expanderRow: {
						style: { borderTopWidth: 2 },
					},
				}}
			/>
		</div>
	)
}

ExpandedComponent.propTypes = {
	data: PropTypes.arrayOf(
		PropTypes.oneOfType([PropTypes.string, PropTypes.array])
	).isRequired,
}

export default ExpandedComponent
