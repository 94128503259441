import { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import PropTypes from 'prop-types'
import customStylesRows, { expanderButton } from './customStyles'
import expandableIcon from './expandableIcon'
import { BottomPage } from '../../components/Tabs/ContentPage'

const animatedBar = () => (
	<div className="h-6 w-48 animate-pulse-fast rounded-md !bg-background" />
)

const SimulateTable = ({
	columns,
	rows = 7,
	dummyEntry = {},
	firstColName = '',
	expanded = false,
	addBottomPage = false,
}) => {
	const [newColumns, setNewColumns] = useState([])
	const [generatedData, setGeneratedData] = useState([])

	useEffect(() => {
		const newColumnsArray = [...columns]
		newColumnsArray[0] = {
			...newColumnsArray[0],
			name: firstColName,
			cell: animatedBar,
		}
		setNewColumns(newColumnsArray)

		const data = []
		for (let i = 0; i < rows; i++) {
			data.push(dummyEntry)
		}
		setGeneratedData(data)
	}, [columns, rows])

	return (
		<div>
			<DataTable
				highlightOnHover
				columns={newColumns}
				data={generatedData}
				customStyles={{ ...customStylesRows, expanderButton }}
				expandableIcon={expandableIcon}
				noDataComponent={<div />}
				className="print:!overflow-hidden"
				expandableRows={expanded}
				expandableRowDisabled={() => true}
			/>

			{addBottomPage && <BottomPage />}
		</div>
	)
}

SimulateTable.propTypes = {
	columns: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
			selector: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
			sortable: PropTypes.bool,
			cell: PropTypes.func,
		})
	).isRequired,
	rows: PropTypes.number,
	dummyEntry: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	firstColName: PropTypes.string,
	expanded: PropTypes.bool,
	addBottomPage: PropTypes.bool,
}

export default SimulateTable
