import { subHours } from 'date-fns'
import ALARM_STATES from './alarmSettings'
import prioritizeAlarms from './prioritizeAlarms'

export const determineState = (measuredValue, inputValue) => {
	if (inputValue === '2' && measuredValue === ALARM_STATES.ALARM) {
		// include '2' in defrost is due to old values, that were saved in this format
		return ALARM_STATES.DEFROST
	}
	if (inputValue !== '1') return ALARM_STATES.NO_ALARM
	return prioritizeAlarms([measuredValue])
}

const processGroupedData = groupedData => {
	return Object.fromEntries(
		Object.entries(groupedData).map(([deviceId, timestamps]) => {
			let previousState = ALARM_STATES.NO_ALARM
			const stateChanges = []

			Object.entries(timestamps)
				.sort(([timeA], [timeB]) => new Date(timeA) - new Date(timeB))
				.forEach(([timestampISO, measurements]) => {
					const adjustedTimestamp = subHours(new Date(timestampISO), 1).toISOString()

					const currentState = prioritizeAlarms(measurements)

					if (currentState !== previousState) {
						stateChanges.push({
							timestamp: adjustedTimestamp,
							value: currentState,
						})
						previousState = currentState
					}
				})

			return [deviceId, stateChanges]
		})
	)
}

const groupData = data => {
	return data.reduce((acc, { deviceId, timestamp, ...rest }) => {
		acc[deviceId] = acc[deviceId] || {}
		acc[deviceId][timestamp] = acc[deviceId][timestamp] || []
		acc[deviceId][timestamp].push(rest)
		return acc
	}, {})
}

const prepareAlarms = alarmsData => {
	const mapped = alarmsData.map(
		({ timestamp, deviceId, value, measuredValue }) => ({
			timestamp,
			deviceId,
			value,
			measuredValue,
		})
	)
	const grouped = groupData(mapped)
	const finalData = processGroupedData(grouped)
	return Object.entries(finalData)
}

export default prepareAlarms
