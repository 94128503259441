import { useRef } from 'react'
import { NavLink, Navigate, Route, Routes, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'

const ParentPage = ({ allLinks, PageContext, contextObject }) => {
	const location = useLocation()
	const parentPageRef = useRef(null)

	const firstLink = allLinks[0].to

	// Prüfe, ob der erste Link aktiv ist
	const isFirstLinkActive = location.pathname.split('/')[4] === firstLink

	return (
		<>
			<div className="flex gap-2.5 text-lg font-medium tracking-wide print:hidden">
				{allLinks.map((link, i) => (
					<NavLink
						key={link.to}
						className={({ isActive }) =>
							(isActive ? 'bg-white ' : '') +
							(i === 0 && !isActive ? 'rounded-bl-2xl ' : '') +
							'rounded-t-2xl py-2 px-4 border-2 border-transparent hover:border-white hover:border-2 hover:border-b-0'
						}
						to={link.to}
					>
						{link.name}
					</NavLink>
				))}
			</div>
			<div
				ref={parentPageRef}
				className={`flex flex-col rounded-2xl bg-white${isFirstLinkActive ? ' !rounded-tl-none' : ''}`}
			>
				<PageContext.Provider value={{ ...contextObject, parentPageRef }}>
					<Routes>
						<Route path="/" element={<Navigate to={firstLink} replace />} />
						{allLinks.map(
							link =>
								link.comp && (
									<Route key={link.to} path={`/${link.to}`} element={<link.comp />} />
								)
						)}
						<Route path="*" element={<Navigate to="" replace />} />
					</Routes>
				</PageContext.Provider>
			</div>
		</>
	)
}

ParentPage.propTypes = {
	allLinks: PropTypes.arrayOf(
		PropTypes.shape({
			to: PropTypes.string.isRequired,
			name: PropTypes.string.isRequired,
			comp: PropTypes.elementType,
		})
	).isRequired,
	PageContext: PropTypes.object.isRequired,
	contextObject: PropTypes.object.isRequired,
}

export default ParentPage
