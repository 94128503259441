import { useContext, useEffect, useState } from 'react'
import { Navigate, useSearchParams } from 'react-router-dom'
import { SystemContext } from '../../shared/context'
import { saveLS } from '../../helper/LocalStorage'
import LoadingPulse from '../../components/Loading/LoadingPulse'
import useRequest from '../../hooks/useRequest'
import { LogoutHandler } from '../../helper/Request'

const redirectUri = window.location.origin + '/login/target'
const clientId = process.env.REACT_APP_CLIENT_ID

const RedirectHandler = () => {
	const [searchParams] = useSearchParams()
	const { LoginHandler } = useContext(SystemContext)
	const { Request, RequestHeader } = useRequest()
	const [loading, setLoading] = useState(true)

	useEffect(() => {
		const start = async () => {
			const code = searchParams.get('code')
			const codeVerifier = sessionStorage.getItem('pkce_code_verifier')
			const authUrl = `/Authentication/Token?grant_type=authorization_code&code=${code}&client_id=${clientId}&redirect_uri=${redirectUri}&code_verifier=${codeVerifier}`
			const tokenRequest = await Request(authUrl, RequestHeader(null, 'POST'))

			if (!tokenRequest.ok) {
				LogoutHandler()
				return
			}

			saveLS('auth', tokenRequest.data)
			await LoginHandler(tokenRequest.data)
			setLoading(false)
		}

		start()
	}, [searchParams, LoginHandler, Request, RequestHeader])

	return (
		<LoadingPulse isFullScreen visible={loading} className="items-center w-full">
			<Navigate to="/" />
		</LoadingPulse>
	)
}

export default RedirectHandler
