import ALARM_STATES from '../pages/app/Temperature/alarmSettings'
import prioritizeAlarms from '../pages/app/Temperature/prioritizeAlarms'

export const correctMinMax = props => {
	const target = props?.target
	const alarm = props?.alarm
	if (!target) {
		return {
			rows: { first: '-', second: '-' },
			string: '-',
			numbers: { min: '-', max: '-' },
			target: { min: '-', max: '-', string: '' },
		}
	}
	const myObj = { string: '', numbers: { min: target.min, max: target.max } }

	// From here currently not in use due to customer needs.
	myObj.min = target.min + ' °C'
	myObj.max = target.max + ' °C'
	if (target.min && target.max) {
		myObj.rows = { first: myObj.min, second: myObj.max }
		myObj.string = myObj.min + ' - ' + myObj.max
	} else if (target.min && !target.max) {
		myObj.rows = { first: 'Min', second: myObj.min }
		myObj.string = `Mindestens ${myObj.min}`
	} else if (!target.min && target.max) {
		myObj.rows = { first: 'Max', second: myObj.max }
		myObj.string = `Maximal ${myObj.max}`
	} else {
		myObj.rows = { first: '', second: '' }
		myObj.string = ''
	}
	// End

	if (alarm.min && !alarm.max) {
		myObj.target = { first: 'Min', second: myObj.min }
		myObj.target.string = 'Mindestens ' + myObj.target.second
	} else if (!alarm.min && alarm.max) {
		myObj.target = { first: 'Max', second: myObj.max }
		myObj.target.string = 'Maximal ' + myObj.target.second
	} else {
		myObj.target = { first: '', second: '' }
		myObj.target.string = ''
	}

	return myObj
}

export const tenantAddress = tenant => {
	const { attributes } = tenant.configuration
	let finalString = ''
	if (attributes.address) finalString += attributes.address + ', '
	if (attributes.zip) finalString += attributes.zip + ' '
	if (attributes.city) finalString += attributes.city
	return finalString
}

export const rolesSplited = rolesString => {
	const roles = rolesString?.split(',').map(role => role.trim()) || []

	const isAdminFilter = roles.filter(role => role === 'Administrator')
	const isAdmin = isAdminFilter.length > 0

	const usecasesPrefix = 'Device Types - '
	const usecasesFilter = roles.filter(role => role.startsWith(usecasesPrefix))
	const usecases = usecasesFilter.map(role => role.slice(usecasesPrefix.length))

	const usecasesIsAllFilter = usecases.filter(usecase => usecase === 'All')
	const usecasesIsAll = isAdmin ? true : usecasesIsAllFilter.length > 0

	const allRoles = {
		admin: isAdmin,
		usecases: { all: usecasesIsAll, roles: usecases },
	}
	return allRoles
}

export const getCurrentAlarmState = attributes => {
	if (!attributes) return null
	const relevantStates = [
		ALARM_STATES.DEFROST,
		ALARM_STATES.WARNING,
		ALARM_STATES.ALARM,
	]

	const filteredMeasurements = Object.keys(attributes)
		.filter(key => relevantStates.includes(key))
		.map(key => ({
			measuredValue: key,
			value: attributes[key],
		}))
	const highestPriority = prioritizeAlarms(filteredMeasurements)
	return highestPriority
}
