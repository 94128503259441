import { useContext } from 'react'
import { tenantAddress } from '../../helper/ShowData'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	// faGear,
	faRightFromBracket,
	faUserCircle,
} from '@fortawesome/pro-solid-svg-icons'
import { UserContext } from '../../shared/context'
import PropTypes from 'prop-types'
import { LogoutHandler } from '../../helper/Request'

const displayedUserName = profile => {
	const { firstname, lastname, username } = profile

	const bothNames = firstname && lastname
	const onlyFirstName = firstname && !lastname
	const onlyLastName = !firstname && lastname

	if (bothNames) return firstname + ' ' + lastname
	if (onlyFirstName) return firstname
	if (onlyLastName) return lastname
	return username
}

const tenantName = tenant => {
	if (tenant.id.toString() === process.env.REACT_APP_ADD_USER_TENANT) {
		return ''
	}
	return tenant.name
}

const Header = ({ tenant }) => {
	const { profile } = useContext(UserContext)

	return (
		<div className="sticky top-0 z-30 flex max-h-16 min-h-16 w-full justify-between bg-white pl-5 pr-1.5 print:hidden">
			<div className="flex flex-col items-start justify-center">
				<div className="text-xl font-medium">{tenantName(tenant)}</div>
				<div className="text-xs font-normal text-accent">
					{tenantAddress(tenant)}
				</div>
			</div>
			<div className="flex h-full text-accent">
				<div className="flex items-center justify-center h-full border-r border-background">
					<div className="font-normal text-text">{displayedUserName(profile)}</div>
					<div className="flex items-center justify-center w-16 h-full">
						<FontAwesomeIcon icon={faUserCircle} className="h-7" />
					</div>
				</div>
				<div className="flex items-center justify-center w-16 h-full border-l border-background">
					<button
						onClick={LogoutHandler}
						className="flex items-center justify-center"
					>
						<FontAwesomeIcon
							icon={faRightFromBracket}
							className="cursor-pointer h-7 hover:text-primary"
						/>
					</button>
				</div>
			</div>
		</div>
	)
}

Header.propTypes = {
	tenant: PropTypes.object,
}

export default Header
