import { useCallback } from 'react'
import useRequest from './useRequest'
import { getDeviceFilter } from '../helper/Filter'
import { combineName } from '../shared/format'

const formatDevices = devices => {
	return devices
		.map(device => {
			return {
				...device,
				filter: combineName(device.attributes) || 'nicht verfügbar',
			}
		})
		.sort((a, b) => a.filter.localeCompare(b.filter))
}

const useLoadDevices = () => {
	const { Request, RequestHeader } = useRequest()

	const loadDevices = useCallback(
		async (token, tenantId, filter) => {
			const myDevices = await Request(
				'/Device?tenantId=' + tenantId,
				RequestHeader(token, 'POST', getDeviceFilter(filter))
			)
			if (!myDevices.ok) return null

			const allDevices = formatDevices(myDevices.data.devices)

			return allDevices
		},
		[Request, RequestHeader]
	)

	return loadDevices
}

export default useLoadDevices
