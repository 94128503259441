import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const ToastNotification = () => (
	<ToastContainer
		position="bottom-right"
		autoClose={5000}
		hideProgressBar
		newestOnTop={false}
		closeOnClick
		rtl={false}
		pauseOnFocusLoss={false}
		draggable
		pauseOnHover={true}
		limit={1}
		theme="colored"
		className="w-auto lg:min-w-toast lg:max-w-toast print:hidden"
	/>
)

export default ToastNotification
