import React, { useEffect } from 'react'
import ReactDOM from 'react-dom/client'

import Router from './pages/Router/Router'

import './index.css'
import './fonts/Inter/inter.css'
import './fonts/HomemadeApple/HomemadeApple.css'
import './fonts/Montserrat/Montserrat.css'

import 'react-datepicker/dist/react-datepicker.css'
import 'react-day-picker/dist/style.css'
import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom'
import { removeLS } from './helper/LocalStorage'

const root = ReactDOM.createRoot(document.getElementById('root'))

const LogoutComponent = () => {
	const navigate = useNavigate()
	useEffect(() => {
		removeLS('auth')
		navigate('/login')
	}, [])
	return <></>
}

const App = () => (
	<div className="flex w-full h-screen font-medium font-inter">
		<BrowserRouter>
			<Routes>
				<Route path="/logout" element={<LogoutComponent />} />
				<Route path="*" element={<Router />} />
			</Routes>
		</BrowserRouter>
	</div>
)

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
	root.render(<App />)
} else {
	root.render(
		<React.StrictMode>
			<App />
		</React.StrictMode>
	)
}
