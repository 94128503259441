import { useContext, useEffect, useState } from 'react'

import { PageContext } from './context'

import ParentPage from '../../../components/Tabs/ParentPage'
import {
	DataContext,
	SystemContext,
	UserContext,
} from '../../../shared/context'
import TemperatureDay from './day/Temperature'
import TemperatureWeek from './week/Temperature'
import Alarms from './alarms/Alarms'
import { Delay } from '../../../helper/Request'
import useLoadDevices from '../../../hooks/useLoadDevices'
import useLoadTimeseries from '../../../hooks/useLoadTimeseries'

import prepareAlarms from './prepareAlarms'
import prepareTemperature from './prepareTemperature'
import { addMonths } from 'date-fns'
import { timeseriesSettings } from './alarmSettings'

const allLinks = [
	{
		to: 'day',
		name: 'Tagesübersicht',
		comp: TemperatureDay,
	},
	{
		to: 'week',
		name: 'Wochenübersicht',
		comp: TemperatureWeek,
	},
	{
		to: 'alarms',
		name: 'Alarme',
		comp: Alarms,
	},
]

const getTimerange = ({ startDate, days, months }) => {
	const dateFromHours = new Date(startDate.setHours(0, 0, 0, 0))
	const from = dateFromHours.toISOString()
	let to = null

	if (months) {
		to = new Date(addMonths(dateFromHours.getTime(), months)).toISOString()
	} else if (days) {
		to = new Date(
			dateFromHours.getTime() + days * 24 * 60 * 60 * 1000
		).toISOString()
	} else {
		to = new Date()
	}

	return { from, to }
}

const startGetData = async (devices, tenantId, setData) => {
	let finalData = null
	setData(finalData)
	// TODO: There is an error where when first loading the router it still has devices.length = 0.
	// But this is false. Check that if there is a workaround since it is falsy shown "No devices in this tenant"
	if (devices?.length === 0) {
		await Delay(500)
		setData([])
		return null
	}

	const allDeviceIds = devices.map(device => device.id)
	const paramsTimeSeries = { deviceId: allDeviceIds, tenantId: tenantId }
	return paramsTimeSeries
}

const TemperatureRouter = () => {
	const { systemJson } = useContext(SystemContext)
	const { token } = useContext(UserContext)
	const { tenant } = useContext(DataContext)
	const [temperatureData, setTemperatureData] = useState(null)
	const [alarmData, setAlarmData] = useState(null)
	const [allDevices, setAllDevices] = useState([])

	const loadDevices = useLoadDevices()
	const loadTimeseries = useLoadTimeseries()

	const getTimeSeries = async (
		type,
		token,
		timeRange,
		paramsTimeSeries,
		abortRef
	) => {
		const config = timeseriesSettings[type]

		if (!config) {
			return null
		}

		const { dataTypes, aggregation, delay } = config

		const myTimeseries = await loadTimeseries(
			token,
			getTimerange(timeRange),
			paramsTimeSeries,
			dataTypes,
			abortRef,
			aggregation,
			delay
		)

		if (!myTimeseries.ok) return null
		return myTimeseries.data
	}

	useEffect(() => {
		const initData = async () => {
			const filter = systemJson.defaultValues.TemperatureDeviceTypes
			const allDevices = await loadDevices(token, tenant.id, filter)
			setAllDevices(allDevices)
		}

		initData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const getTemperatureData = async (abortRef, timeRange, devices) => {
		const paramsTimeSeries = await startGetData(
			devices,
			tenant.id,
			setTemperatureData
		)
		if (!paramsTimeSeries) return

		const temperatureData = await getTimeSeries(
			'temp',
			token,
			timeRange,
			paramsTimeSeries,
			abortRef
		)
		if (!temperatureData) return null

		const alarmsData = await getTimeSeries(
			'alarms',
			token,
			timeRange,
			paramsTimeSeries,
			abortRef
		)
		if (!alarmsData) return null

		const finalData = prepareTemperature(
			alarmsData,
			temperatureData,
			{ devices, timeRange },
			systemJson
		)

		setTemperatureData(finalData)
	}

	const getAlarmData = async (abortRef, timeRange, devices) => {
		const paramsTimeSeries = await startGetData(devices, tenant.id, setAlarmData)
		if (!paramsTimeSeries) return

		const alarmsData = await getTimeSeries(
			'alarms',
			token,
			timeRange,
			paramsTimeSeries,
			abortRef
		)
		if (!alarmsData) return null

		const finalData = prepareAlarms(alarmsData)

		setAlarmData(finalData)
	}

	return (
		<ParentPage
			allLinks={allLinks}
			PageContext={PageContext}
			contextObject={{
				getTemperatureData,
				temperatureData,
				allDevices,
				getAlarmData,
				alarmData,
			}}
		/>
	)
}

export default TemperatureRouter
