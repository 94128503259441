import DataTable from 'react-data-table-component'
import { saveLS } from '../../../helper/LocalStorage'
import PropTypes from 'prop-types'

const paginationComponentOptions = {
	rowsPerPageText: 'Einträge per Seite',
	rangeSeparatorText: 'von',
	selectAllRowsItem: true,
	selectAllRowsItemText: 'Alle',
	paginationPerPage: 25,
}

const paginationRowsPerPageOptions = [5, 10, 25, 50, 75, 100, 150, 200]

const saveSortToLS = cols => {
	saveLS(
		'tenantSearch_sort',
		cols.map(col => col.id)
	)
}

const DataTableComponent = ({ columns, data }) => (
	<DataTable
		highlightOnHover
		columns={columns}
		data={data}
		pagination
		paginationPerPage={25}
		paginationRowsPerPageOptions={paginationRowsPerPageOptions}
		paginationComponentOptions={paginationComponentOptions}
		customStyles={{
			rows: {
				highlightOnHoverStyle: {
					cursor: 'pointer',
				},
				style: {
					height: '48px',
				},
			},
			pagination: {
				style: {
					borderBottomLeftRadius: '1rem',
					borderBottomRightRadius: '1rem',
				},
			},
		}}
		fixedHeader
		fixedHeaderScrollHeight="calc(100vh - 17rem)"
		persistTableHead
		onColumnOrderChange={cols => saveSortToLS(cols)}
		noDataComponent={<div />}
	/>
)

DataTableComponent.propTypes = {
	columns: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string.isRequired,
			selector: PropTypes.oneOfType([
				PropTypes.string,
				PropTypes.func,
				PropTypes.oneOf([undefined]),
			]),
			sortable: PropTypes.bool,
		})
	).isRequired,
	data: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default DataTableComponent
