import { faTimes } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import useClickOutside from '../../hooks/useClickOutside'

const expandedWidth = 300

export const HeaderButtons = ({
	children,
	onClick,
	className = '',
	text,
	selected,
	showOnPrint = false,
	toInput,
	inputText,
	onInputChange,
	onInputClear,
}) => {
	const inputRef = useRef(null)
	const containerRef = useRef(null)
	const [style, setStyle] = useState({})
	const [initWidth, setInitWidth] = useState('')
	const [isExpanded, setIsExpanded] = useState(false)

	const focusRef = () => inputRef.current.focus()

	const toggleExpand = (e, forceClosing = false) => {
		const close = () => {
			setTimeout(() => {
				setIsExpanded(false)
			}, 450)
			setStyle({ width: initWidth + 'px' })
		}
		const open = () => {
			setIsExpanded(true)
			setStyle({ width: expandedWidth + 'px' })
			setTimeout(focusRef, 500) // Give some time for the animation to complete
		}

		if (forceClosing) {
			close()
			return
		}

		if (containerRef.current.offsetWidth === expandedWidth) {
			close()
		} else {
			open()
		}
	}

	useEffect(() => {
		const currentWidth = containerRef.current.offsetWidth
		setInitWidth(currentWidth)
		setStyle({ width: currentWidth })
	}, [])

	useClickOutside(isExpanded, containerRef, toggleExpand, inputText)

	const isNotExpandedOnClick = toInput ? toggleExpand : onClick

	const handleKeyDown = e => {
		if (e.key === 'Escape') {
			toggleExpand(null, true)
		}
	}

	return (
		<div ref={containerRef}>
			<button
				onClick={!isExpanded ? isNotExpandedOnClick : focusRef}
				style={style}
				className={
					(isExpanded ? '' : 'hover:bg-[#cfcfcf] ') +
					(selected || isExpanded ? 'border-[#cfcfcf] ' : 'border-transparent ') +
					(showOnPrint ? '' : 'print:hidden ') +
					'h-10 border-2 relative cursor-pointer rounded-md px-2.5 flex items-center justify-start transition-all duration-500 ease-in-out ' +
					className
				}
				tabIndex={toInput || onClick ? 0 : undefined}
				onKeyDown={e => {
					if ((e.key === 'Enter' || e.key === ' ') && !isExpanded) {
						isNotExpandedOnClick(e)
					}
				}}
				role={toInput || onClick ? 'button' : undefined}
			>
				{children}
				{isExpanded && (
					<>
						<input
							ref={inputRef}
							type="text"
							className="w-full h-10 px-2 text-black placeholder-black placeholder-opacity-50 bg-transparent border-0 rounded-md outline-0 ring-0"
							placeholder={text}
							onChange={onInputChange}
							value={inputText}
							aria-label={text}
							onKeyDown={handleKeyDown}
						/>
						{inputText !== '' && (
							<button
								onClick={e => {
									toggleExpand(e, true)
									onInputClear()
								}}
								className="ml-2"
								aria-label="Clear input"
								tabIndex={0}
							>
								<FontAwesomeIcon icon={faTimes} className="hover:text-accent" />
							</button>
						)}
					</>
				)}
				{text && !isExpanded && <div className="ml-2">{text}</div>}
			</button>
		</div>
	)
}

HeaderButtons.propTypes = {
	children: PropTypes.node,
	onClick: PropTypes.func,
	className: PropTypes.string,
	text: PropTypes.string,
	selected: PropTypes.bool,
	showOnPrint: PropTypes.bool,
	toInput: PropTypes.bool,
	inputText: PropTypes.string,
	onInputChange: PropTypes.func,
	onInputClear: PropTypes.func,
}
