import { useEffect } from 'react'
import useRequest from '../../../hooks/useRequest'
import { fetchFileServer } from '../../../helper/fetch/FileServer'

// Hook für das Laden der JSON-Dateien
const useFetchSystemJson = (setSystemJson, forbiddenTenantsInSearch) => {
	const { Request } = useRequest()
	useEffect(() => {
		const fetchAllFileServer = async () => {
			const temperatureThresholds = await fetchFileServer(
				Request,
				'temperatureThresholds.json'
			)
			const defaultValues = await fetchFileServer(Request, 'defaultValues.json')

			setSystemJson({
				temperatureThresholds,
				defaultValues,
				forbiddenTenantsInSearch,
			})
		}
		fetchAllFileServer()
	}, [])
}

export default useFetchSystemJson
