import { useContext, useEffect, useState } from 'react'

import { JsonContext } from '../../shared/context'
import packageJson from '../../../package.json'

import TabLink from './TabLink'
import Logo from '../../shared/Logo'

const Sidebar = () => {
	const { AllLinks } = useContext(JsonContext)
	const [isHovered, setIsHovered] = useState(false)
	const [showText, setShowText] = useState(false)
	const [timerId, setTimerId] = useState(null)

	const handleMouseEnter = () => {
		setIsHovered(true)
		const id = setTimeout(() => {
			setShowText(true)
		}, 300)
		setTimerId(id)
	}

	const handleMouseLeave = () => {
		if (timerId) {
			clearTimeout(timerId)
		}
		setIsHovered(false)
		setShowText(false)
	}

	useEffect(() => {
		return () => {
			if (timerId) clearTimeout(timerId)
		}
	}, [timerId])

	return (
		<div
			className={
				(isHovered ? 'min-w-60 max-w-60 ' : 'min-w-16 max-w-16 ') +
				'z-40 flex flex-col items-center justify-start h-full bg-white print:hidden transition-all duration-300 ease-in-out overflow-hidden'
			}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
		>
			<div className="m-2.5 flex h-11 flex-row items-start justify-start self-stretch">
				<Logo className="h-11" alt="SENS LOGO BALL" file="logo_ball.svg" />
				{showText && (
					<div className="flex items-center h-full ml-3 text-3xl font-montserrat text-logo">
						<span className="font-bold">SENS</span>
						<span className="font-normal">HUB</span>
					</div>
				)}
			</div>
			<div className="w-full h-16"></div>
			<div className="flex flex-col w-full gap-2">
				{AllLinks.map(
					thisLink =>
						thisLink.show && (
							<TabLink
								key={'sidebarlink' + thisLink.to}
								{...thisLink}
								isHovered={isHovered}
								showText={showText}
							/>
						)
				)}
			</div>
			<div className="w-full grow"></div>
			<div className="w-full mb-1 text-sm text-center text-accent">
				{showText && 'version: '}
				{packageJson.version}
			</div>
		</div>
	)
}

export default Sidebar
