import { useEffect } from 'react'
import { NavLink, useSearchParams } from 'react-router-dom'
import { loginUrl } from './helper'
import Logo from '../../shared/Logo'
import PropTypes from 'prop-types'

const redirectUri = window.location.origin + '/login/target'
const clientId = process.env.REACT_APP_CLIENT_ID

const ToRedirect = props => {
	const [searchParams] = useSearchParams()
	const iotaLoginURL = loginUrl(
		redirectUri,
		clientId,
		props.allowSSO ? searchParams.get('sso_id') : null
	)
	useEffect(() => {
		setTimeout(() => {
			window.location.href = iotaLoginURL
		}, 2000)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<div className="absolute flex flex-col w-full gap-8 p-10 -translate-x-1/2 -translate-y-1/2 left-1/2 top-1/2 font-montserrat">
			<div className="text-4xl font-semibold text-center">Willkommen zurück!</div>
			<div className="relative flex justify-center">
				<Logo
					file="senshub_trans.svg"
					alt="SENSHUB_transparent"
					className="h-24 text-logo md:h-32"
				/>
			</div>
			<div className="text-xl text-center">
				<div>Sie werden nun auf unsere externe Loginseite weitergeleitet.</div>
				<div>
					Sollten Sie nach 5 Sekunden immer noch nicht weitergeleitet werden, klicken
					Sie bitte
					<NavLink to={iotaLoginURL}>
						<b> hier</b>
					</NavLink>
					!
				</div>
			</div>
		</div>
	)
}

ToRedirect.propTypes = {
	allowSSO: PropTypes.bool,
}

export default ToRedirect
