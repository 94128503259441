import { useContext, useEffect, useState } from 'react'
import { Navigate, Route, Routes, useParams } from 'react-router-dom'

import { DataContext, JsonContext } from '../../shared/context'

const AppRouter = () => {
	const { AllLinks } = useContext(JsonContext)
	const { changeTenant, tenant } = useContext(DataContext)
	const [loading, setLoading] = useState(false)

	const params = useParams()

	useEffect(() => {
		const waitChangedTenant = async () => {
			setLoading(true)
			await changeTenant(params.tenantId)
			setLoading(false)
		}
		waitChangedTenant()
	}, [changeTenant, params.tenantId])

	return (
		tenant &&
		!loading && (
			<Routes>
				<Route path="/" element={<Navigate to="home" replace />} />
				{AllLinks.map(link => {
					const Element = link.element
					return (
						link.show && (
							<Route
								key={'allLinks_' + link.to}
								path={'/' + link.to + (link.subPaths ? '/*' : '')}
								element={<Element />}
							/>
						)
					)
				})}
				<Route path="*" element={<Navigate to="/" />} />
			</Routes>
		)
	)
}

export default AppRouter
