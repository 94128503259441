// eslint-disable-next-line no-unused-vars
const AllCodesSuccess = (code, params) => {
	// 1xxxxx
	switch (code.substring(1, 6)) {
		// 11xxx - Login
		case '11100':
			return 'Sie wurden erfolgreich ausgeloggt.'
		case '11101':
			return 'Eine Email wurde erfolgreich an Sie versendet.'
		case '11102':
			return 'Ihr Passwort wurde erfolgreich zurückgesetzt. Bitte melden Sie sich nun an.'

		// 15xxx - Up-/Download
		case '15100':
			return 'Die Datei wurde erfolgreich heruntergeladen.'

		// If code is not defined
		default:
			return null
	}
}

const AllCodesError = (code, params) => {
	// 2xxxxxx
	switch (code.substring(1, 6)) {
		// 10xxx - General
		case '10100':
			return 'Es tut uns leid. Etwas ist auf unserer Seite schiefgelaufen.'
		case '10101':
			return 'Dies ist kein korrekter Fehlercode.'
		case '10102':
			return `Bitte geben Sie mindestens ${params?.chars} Zeichen ein.`
		case '10103':
			return 'Diese Funktion ist erst in Kürze verfügbar.'
		case '10104':
			return `Ungültiges Eingabeformat. Verwenden Sie dieses Eingabeformat: "${params.format}."`
		case '10105':
			return 'Bitte überprüfen Sie Ihre Firewall/Browser Einstellungen. Uns wurde der Zugriff verweigert. Andernfalls kontaktieren Sie bitte den Support.'

		// 11xxx - Login
		case '11100':
			return 'Benutzername oder Passwort sind falsch.'
		case '11101':
			return 'Sie wurden automatisch ausgeloggt. Bitte erneut anmelden.'
		case '11102':
			return 'Tut uns leid. Es ist ein Problem mit dem Login aufgetreten.'
		case '11103':
			return 'Leider haben Sie ihr Passwort zu oft falsch eingegeben. Bitte kontaktieren Sie den Support.'
		case '11104':
			return 'Leider konnte das Passwort nicht wiederhergestellt werden. Bitte kontaktieren Sie den Support.'
		case '11105':
			return 'Leider konnte das Zurücksetzen des Passworts nicht abgeschlossen werden. Bitte kontaktieren Sie den Support.'
		case '11106':
			return 'Leider konnte das Passwort nicht neu gesetzt werden. Bitte kontaktieren Sie den Support.'

		// 12xxx - Tenant
		case '12100':
			return 'Es konnten keine Standorte gefunden werden.'
		case '12101':
			return 'Sie haben keinen Zugriff auf diesen Standort.'
		case '12102':
			return 'Sie haben keine Berechtigungen auf unserem System.'

		// 13xxx - Device Data
		case '13100':
			return 'Es konnten keine Geräte geladen werden.'
		case '13101':
			return 'Das Gerät konnte nicht geladen werden.'

		// 14xxx - User/Profile
		case '14100':
			return 'Das Profil konnte nicht geladen werden.'

		// 15xxx - Up-/Download
		case '15100':
			return 'Die Datei konnte nicht heruntergeladen werden.'
		case '15101':
			return 'Externe Daten konnte nicht geladen werden.'

		// 16xxx - Device Attributes
		case '16100':
			return 'Es gab ein Problem, das Foto zu laden.'
		case '16101':
			return 'Es konnte kein Foto für dieses Gerät gefunden werden.'

		// If code is not defined
		default:
			return null
	}
}

const AllCodes = {
	AllCodesError,
	AllCodesSuccess,
}

export default AllCodes
