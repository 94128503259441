import PropTypes from 'prop-types'

const BgImage = ({ children, src, alt, blurred, blackBg }) => {
	return (
		<div className="relative h-full w-full">
			<div className="absolute left-0 top-0 h-full w-full">
				<img
					src={src}
					alt={alt}
					className={
						(blurred ? 'filter blur-sm ' : '') + 'object-cover w-full h-full'
					}
				/>
				{blackBg && (
					<div className="absolute left-0 top-0 h-full w-full rounded-lg bg-black opacity-45"></div>
				)}
			</div>

			<div className="absolute left-0 top-0 flex h-full w-full items-center justify-center tracking-widest">
				{children}
			</div>
		</div>
	)
}

BgImage.propTypes = {
	children: PropTypes.node,
	src: PropTypes.string.isRequired,
	alt: PropTypes.string.isRequired,
	blurred: PropTypes.bool,
	blackBg: PropTypes.bool,
}

export default BgImage
