import { useContext } from 'react'
import { DataContext } from '../../../shared/context'
import { tenantAddress } from '../../../helper/ShowData'
import PropTypes from 'prop-types'

const DisplayDate = ({ children }) => {
	const { tenant } = useContext(DataContext)

	return (
		<div className="flex gap-1.5 truncate text-xl font-normal print:-ml-4 print:text-lg print:font-medium">
			<div className="flex items-center justify-center">{children}</div>
			<div className="hidden print:block">|</div>
			<div className="hidden print:block">{tenant.name}</div>
			<div className="hidden italic print:block">({tenantAddress(tenant)})</div>
		</div>
	)
}

DisplayDate.propTypes = {
	children: PropTypes.node.isRequired,
}

export default DisplayDate
