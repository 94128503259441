import { useEffect, useState } from 'react'
import ContentPage, {
	BottomPage,
	HeaderPage,
} from '../../../../components/Tabs/ContentPage'
import { startOfMonth } from 'date-fns'
import Table from './Table'
import { useReplaceSearchParams } from '../../../../helper/UrlParams'
import { useSearchParams } from 'react-router-dom'
import {
	formatCustomMonth,
	validateDateFormat,
} from '../../../../helper/ValidateFormat'
import Header from './Header'
import TemperatureExport from '../TemperatureExport'

const Alarms = () => {
	const [searchParams] = useSearchParams()
	const [currentMonth, setCurrentMonth] = useState(null)

	const replaceSearchParams = useReplaceSearchParams()

	useEffect(() => {
		const queryMonth = searchParams.get('month')
		const validMonth = validateDateFormat(queryMonth, 'MMyyyy')
		const formattedCurrentMonth = formatCustomMonth(startOfMonth(new Date()))

		if (!validMonth) {
			replaceSearchParams({ month: formattedCurrentMonth })
		} else {
			setCurrentMonth(startOfMonth(validMonth))
		}
	}, [searchParams])

	// TODO: Find a better solution for that
	if (!currentMonth) {
		return <></>
	}

	return (
		<>
			<HeaderPage
				Export={<TemperatureExport currentMonth={currentMonth} disableCSV />}
			>
				<Header currentMonth={currentMonth} />
			</HeaderPage>
			<ContentPage>
				<Table currentMonth={currentMonth} />
			</ContentPage>
			<BottomPage />
		</>
	)
}

export default Alarms
