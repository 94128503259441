import PropTypes from 'prop-types'
import { SystemContext, UserContext } from '../../shared/context'

const ContextsProvider = ({ children, systemContext, userContext }) => (
	<SystemContext.Provider value={systemContext}>
		<UserContext.Provider value={userContext}>{children}</UserContext.Provider>
	</SystemContext.Provider>
)

ContextsProvider.propTypes = {
	children: PropTypes.node.isRequired,
	systemContext: PropTypes.shape({
		systemJson: PropTypes.object.isRequired,
		setDialogStructure: PropTypes.func.isRequired,
		openDialogHandler: PropTypes.func.isRequired,
		dropdownState: PropTypes.arrayOf(
			PropTypes.oneOfType([PropTypes.object.isRequired, PropTypes.func.isRequired])
		).isRequired,
		LoginHandler: PropTypes.func.isRequired,
	}).isRequired,
	userContext: PropTypes.shape({
		profile: PropTypes.object.isRequired,
		auth: PropTypes.object.isRequired,
		token: PropTypes.string,
	}).isRequired,
}

export default ContextsProvider
