export const filterTenant = (input, page = 0, pageSize = 100) => {
	return {
		page: page,
		pageSize: pageSize,
		filter: {
			condition: {
				'@operator': 'or',
				column: [
					{
						'@datatype': 'string',
						'@name': 'name',
						'@operator': 'ilike',
						'@relative': 'False',
						'@value': `%${input}%`,
					},
					// For the tenant config attributes
					...['address', 'zip', 'city', 'market_id'].map(field => ({
						'@datatype': 'string',
						'@name': 'configuration',
						'@operator': 'like',
						'@value': `%${field}": "${input}%`,
					})),
				],
			},
			column: [],
		},
	}
}

export const getDeviceFilter = filter => {
	return {
		page: 0,
		pageSize: 100000,
		sortOrder: 'asc',
		filter: {
			condition: [
				{
					'@operator': 'or',
					column: filter.map(type => {
						return {
							'@name': 'Type',
							'@operator': 'like',
							'@value': type,
							'@datatype': 'string',
						}
					}),
				},
			],
		},
	}
}
