import { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

const GRID_COLUMNS = 12
const GRID_GAP = 16 // spacing between the grid items
const GRID_ROW_HEIGHT = 24 // height of each row in the grid

const snapToGrid = (col, y, w, h) => {
	// Berechnungen für col
	const minCol = 0
	const maxCol = GRID_COLUMNS - w
	const roundedCol = Math.round(col)
	const snappedCol = Math.min(Math.max(roundedCol, minCol), maxCol)

	// Berechnungen für y
	const rowHeightWithGap = GRID_ROW_HEIGHT + GRID_GAP
	const roundedY = Math.round(y / rowHeightWithGap) * rowHeightWithGap
	const snappedY = Math.max(roundedY, 0)

	// Berechnungen für w
	const minW = 1
	const maxW = GRID_COLUMNS - col
	const roundedW = Math.round(w)
	const snappedW = Math.min(Math.max(roundedW, minW), maxW)

	// Berechnungen für h
	const minH = 1
	const snappedH = Math.max(Math.round(h), minH)

	return { col: snappedCol, y: snappedY, w: snappedW, h: snappedH }
}

const Panel = ({ panelPos, columnWidth, onDrag, onResize, editable }) => {
	const { id, col, y, w, h, transparent, Element } = panelPos

	const handleDragStart = e => {
		if (!editable) return
		const startX = e.clientX
		const startY = e.clientY

		const handleDrag = e => {
			const newCol = col + (e.clientX - startX) / (columnWidth + GRID_GAP)
			const newY = y + e.clientY - startY
			const snappedPosition = snapToGrid(newCol, newY, w, h)
			onDrag(id, snappedPosition.col, snappedPosition.y)
		}

		const handleDragEnd = () => {
			document.removeEventListener('mousemove', handleDrag)
			document.removeEventListener('mouseup', handleDragEnd)
		}

		document.addEventListener('mousemove', handleDrag)
		document.addEventListener('mouseup', handleDragEnd)
	}

	const handleResizeStart = e => {
		if (!editable) return
		e.stopPropagation()

		const preventDefault = e => e.preventDefault()
		document.addEventListener('selectstart', preventDefault)

		const startX = e.clientX
		const startY = e.clientY
		const initialWidth = w
		const initialHeight = h

		const handleResize = e => {
			const deltaX = e.clientX - startX
			const deltaY = e.clientY - startY
			const newW = initialWidth + deltaX / (columnWidth + GRID_GAP)
			const newH = initialHeight + deltaY / (GRID_ROW_HEIGHT + GRID_GAP)
			const snappedSize = snapToGrid(col, y, newW, newH)
			onResize(id, snappedSize.w, snappedSize.h)
		}

		const handleResizeEnd = () => {
			document.removeEventListener('mousemove', handleResize)
			document.removeEventListener('mouseup', handleResizeEnd)
			document.removeEventListener('selectstart', preventDefault)
		}

		document.addEventListener('mousemove', handleResize)
		document.addEventListener('mouseup', handleResizeEnd)
	}

	const left = col * (columnWidth + GRID_GAP)
	const width = w * columnWidth + (w - 1) * GRID_GAP
	const height = h * GRID_ROW_HEIGHT + (h - 1) * GRID_GAP

	return (
		<div
			className={`${transparent ? 'bg-transparent' : 'bg-white'} absolute flex w-full items-center justify-center rounded-2xl border p-2`}
			style={{ left, top: y, width, height }}
		>
			{editable && (
				<button
					className="z-50 resize-handle"
					onMouseDown={handleResizeStart}
					onKeyDown={e => {
						if (e.key === 'Enter' || e.key === ' ') {
							e.preventDefault()
							handleResizeStart(e)
						}
					}}
				/>
			)}
			<div
				className={`z-40 w-full h-full overflow-auto ${editable ? 'cursor-grab' : ''}`}
				onMouseDown={editable ? handleDragStart : undefined}
				onKeyDown={
					editable
						? e => {
								if (e.key === 'Enter' || e.key === ' ') {
									e.preventDefault()
									handleDragStart(e)
								}
							}
						: undefined
				}
				role={editable ? 'application' : undefined}
				aria-label={editable ? 'Draggable area' : undefined}
			>
				{Element ? <Element /> : <div className="w-full h-full bg-white"></div>}
			</div>
		</div>
	)
}

Panel.propTypes = {
	panelPos: PropTypes.shape({
		id: PropTypes.number.isRequired,
		col: PropTypes.number.isRequired,
		y: PropTypes.number.isRequired,
		w: PropTypes.number.isRequired,
		h: PropTypes.number.isRequired,
		transparent: PropTypes.bool,
		Element: PropTypes.elementType,
	}).isRequired,
	columnWidth: PropTypes.number.isRequired,
	onDrag: PropTypes.func.isRequired,
	onResize: PropTypes.func.isRequired,
	editable: PropTypes.bool.isRequired,
}

const HomeGrid = ({ editable = true, initialPanels }) => {
	const [panels, setPanels] = useState(initialPanels)
	const [columnWidth, setColumnWidth] = useState(0)
	const [isMobileView, setIsMobileView] = useState(false)
	const [allowDragSize, setAllowDragSize] = useState(editable)
	const containerRef = useRef(null)
	const prevPanelsRef = useRef(initialPanels)

	const arrangePanelsForMobileView = () => {
		const sortedPanels = [...panels].sort((a, b) => a.y - b.y || a.col - b.col)
		let currentY = 0
		const newPanels = sortedPanels.map(panel => {
			const newPanel = {
				...panel,
				col: 0,
				y: currentY,
				w: GRID_COLUMNS,
			}
			currentY += panel.h * (GRID_ROW_HEIGHT + GRID_GAP)
			return newPanel
		})
		setPanels(newPanels)
	}

	useEffect(() => {
		const handleResize = () => {
			if (containerRef.current) {
				const containerWidth = containerRef.current.offsetWidth
				const newColumnWidth =
					(containerWidth - (GRID_COLUMNS - 1) * GRID_GAP) / GRID_COLUMNS
				setColumnWidth(newColumnWidth)

				const isMobile = window.innerWidth < 720 // Tailwind's md breakpoint is 720px
				if (isMobile !== isMobileView) {
					setIsMobileView(isMobile)
					if (isMobile) {
						prevPanelsRef.current = panels
						arrangePanelsForMobileView()
					} else {
						setPanels(prevPanelsRef.current)
					}
				}
			}
		}

		const observer = new ResizeObserver(handleResize)
		if (containerRef.current) {
			observer.observe(containerRef.current)
		}

		handleResize()
		window.addEventListener('resize', handleResize)

		return () => {
			window.removeEventListener('resize', handleResize)
			if (containerRef.current) {
				// eslint-disable-next-line react-hooks/exhaustive-deps
				observer.unobserve(containerRef.current)
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isMobileView, panels])

	useEffect(() => {
		if (containerRef.current) {
			const maxY = Math.max(
				...panels.map(
					panel => panel.y + panel.h * (GRID_ROW_HEIGHT + GRID_GAP) - GRID_GAP
				)
			)
			containerRef.current.style.height = `${maxY}px`
		}
	}, [panels])

	const handleDrag = (id, newCol, newY) => {
		setPanels(
			panels.map(panel =>
				panel.id === id ? { ...panel, col: newCol, y: Math.max(newY, 0) } : panel
			)
		)
	}

	const handleResize = (id, newW, newH) => {
		const containerHeight = containerRef.current.offsetHeight
		setPanels(
			panels.map(panel =>
				panel.id === id
					? {
							...panel,
							w: newW,
							h: Math.min(newH, containerHeight / GRID_ROW_HEIGHT),
						}
					: panel
			)
		)
	}

	return (
		<>
			{editable && (
				<div className="bottom-0 left-0 hidden w-full gap-4 p-4 mb-4 bg-gray-100 rounded-xl md:flex">
					<textarea
						className="w-3/4 h-16 p-2 border md:w-5/6"
						readOnly
						value={JSON.stringify(panels)}
					/>
					<button
						className="flex items-center justify-center h-16 p-1 text-center cursor-pointer grow rounded-xl bg-cyan-500"
						onClick={() => setAllowDragSize(!allowDragSize)}
					>
						Bearbeiten {allowDragSize ? 'aktiv' : 'inaktiv'}
					</button>
				</div>
			)}
			<div ref={containerRef} className="relative w-full overflow-hidden">
				{panels.map(panel => (
					<Panel
						key={panel.id}
						panelPos={panel}
						columnWidth={columnWidth}
						onDrag={handleDrag}
						onResize={handleResize}
						editable={isMobileView ? false : allowDragSize}
					/>
				))}
			</div>
		</>
	)
}

HomeGrid.propTypes = {
	editable: PropTypes.bool,
	initialPanels: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number.isRequired,
			col: PropTypes.number.isRequired,
			y: PropTypes.number.isRequired,
			w: PropTypes.number.isRequired,
			h: PropTypes.number.isRequired,
			transparent: PropTypes.bool,
			Element: PropTypes.elementType,
		})
	).isRequired,
}

export default HomeGrid
