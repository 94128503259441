import { useContext } from 'react'
import PropTypes from 'prop-types'

import { combineName } from '../../../../shared/format'
import { correctMinMax } from '../../../../helper/ShowData'

import { SystemContext } from '../../../../shared/context'

const BackButton = ({ device }) => {
	const { systemJson } = useContext(SystemContext)

	const attributes = device?.attributes || null

	return (
		<div className="flex flex-col justify-center h-full ml-1">
			<div className="font-semibold">{combineName(attributes)}</div>
			<div className="italic text-xss">
				{
					correctMinMax(
						systemJson.temperatureThresholds.find(
							({ place }) => place === attributes?.installation_place
						)
					).target.string
				}
			</div>
		</div>
	)
}

BackButton.propTypes = {
	device: PropTypes.object,
}

export default BackButton
