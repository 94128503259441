import { useEffect, useRef } from 'react'

// Helper Funktionen
const abortOnClose = controllerRef => {
	if (controllerRef.current instanceof AbortController) {
		controllerRef.current.abort()
	}
}

const initAbort = controllerRef => {
	if (controllerRef.current instanceof AbortController) {
		controllerRef.current.abort()
	}
	controllerRef.current = new AbortController() // Create a new controller
	return controllerRef
}

// Custom Hook
const useAbortFetch = (callback, dependencies) => {
	const abortControllerRef = useRef(null)

	useEffect(() => {
		const executeCallback = async () => {
			initAbort(abortControllerRef)

			await callback(abortControllerRef.current)
		}

		executeCallback()

		return () => {
			abortOnClose(abortControllerRef)
		}
	}, dependencies)
}

export default useAbortFetch
